import { environment } from '@/environments/environment'
export default [
  {
    name: 'getCountriesQuoter',
    url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries`,
    method: 'get',
    notCancel: true
  },
  {
    name: 'getPlacesQuoter',
    url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/places/full-level-search`,
    method: 'get',
    notCancel: true
  },
  {
    name: 'getServiceTypesQuoter',
    url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/service-types`,
    method: 'get',
    notCancel: true
  },
  { name: 'getPricingQuoter', url: `${environment.newyorkApiUrl}/v1/prices`, method: 'get', notCancel: true },
  {
    name: 'getComparePricingQuoter',
    url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/from-places/{from_place_id}/service-types/{service_type_id}/comparator-tool`,
    method: 'get',
    notCancel: true
  },
  {
    name: 'getInfoCarrier',
    url: `${environment.tokyoApiUrl}/v1/carriers/carrier-services/for-pricing`,
    method: 'get',
    notCancel: true
  },
  { name: 'getComparePricingQuoterForPrices', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/from-places/{from_place_id}/service-types/{service_type_id}/comparator-tool/prices`, method: 'get', notCancel: true},
  { name: 'getAllCouriersQuoterForPrices', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/from-places/{from_place_id}/service-types/{service_type_id}/comparator-tool/prices/all-carriers`, method: 'get', notCancel: true},
  { name: 'getFileComparator', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/from-places/{from_place_id}/service-types/{service_type_id}/comparator-tool/generate-file`, method: 'get', notCancel: true, responseType: 'blob'},
  { name: 'getSimulatorFile', url: `${environment.newyorkApiUrl}/v3/coverage/optimization/simulator`, method: 'post', notCancel: true, headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'getSimulatorByParams', url: `${environment.newyorkApiUrl}/v3/coverage/optimization/simulator/by-params`, method: 'post', notCancel: true, headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'getCostSimulatorFile', url: `${environment.newyorkApiUrl}/v3/coverage/costs/optimization/simulator`, method: 'post', notCancel: true, headers: {'Content-Type': 'multipart/form-data'}}
]
