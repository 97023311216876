export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/modules/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/dashboard-full',
    name: 'dashboard-full',
    component: () => import('@/views/modules/main/DashboardFull.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ],
      resource: 'common',
      action: 'manage'
      // layout: 'vertical'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/modules/main/Dashboard.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ],
      resource: 'menu',
      action: 'dashboard',
      layout: 'full'
    }
  },
  {
    path: '/deliveries/beta/create',
    name: 'deliveries-create',
    component: () => import('@/views/modules/deliveries/create/TabsDeliveries.vue'),
    meta: {
      resource: 'menu',
      action: 'dashboard',
      layout: 'full'
    }
  },
  {
    path: '/deliveries/create',
    name: 'deliveries-create',
    component: () => import('@/views/modules/deliveries/create/Tabs.vue'),
    meta: {
      // breadcrumb: [
      //   {
      //     icon: 'SendIcon',
      //     active: true
      //   },
      //   {
      //     text: 'Crear envíos',
      //     active: true
      //   }
      // ],
      resource: 'menu',
      action: 'dashboard',
      layout: 'full'
    }
  },
  {
    path: '/deliveries/create/labels',
    name: 'deliveries-create-labels',
    component: () => import('@/views/modules/deliveries/create/generate-labels/GenerateLabels.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/deliveries/create/observation',
    name: 'deliveries-create-observation',
    component: () => import('@/views/modules/deliveries/create/observation/Observation.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  // INICIO: Sección rutas para tabs deliveries/create
  {
    path: '/deliveries/create/print',
    name: 'deliveries-create', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/deliveries/create/print/Print.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/printed',
    name: 'deliveries-create', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/deliveries/create/printed/Printed.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  // FINAL: Sección rutas para tabs deliveries/create

  // Sección rutas para Manifiestos
  {
    path: '/summaries/:id_summary',
    name: 'summary-detail', // Archivo de traducciones
    component: () => import('@/views/modules/summaries/SummaryDetail.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/deliveries',
    name: 'deliveries-list',
    component: () => import('@/views/modules/deliveries/list-deliveries/List.vue'),
    meta: {
      breadcrumb: [
        {
          icon: 'SendIcon',
          active: true
        },
        {
          text: 'Listado de envíos',
          active: true
        }
      ],
      layout: 'full',
      resource: 'common',
      action: 'manage'
    }
  },
  // Ruta de prueba de vista
  {
    path: '/deliveries/:id',
    name: 'deliveries-details',
    component: () =>
      import(
        '@/views/modules/deliveries/list-deliveries/detail-delivery/Details.vue'
      ),
    meta: {
      breadcrumb: [
        {
          icon: 'SendIcon',
          active: true
        },
        {
          active: true,
          text: 'Envío {id}'
        }
      ],
      resource: 'deliveries',
      action: 'details',
      layout: 'full'
    }
  },
  {
    path: '/returns',
    name: 'returns',
    component: () => import('@/views/modules/returns/create-returns/Tabs.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  // PROVITIONAL ROUTE FROM PLATFORM
  {
    path: '/returns/create',
    name: 'returns-create', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/returns/create-returns/new-return/Create.vue'),
    meta: {
      layout: 'full',
      resource: 'menu', // resource: 'returns',
      action: 'dashboard' // action: 'create'
    }
  },
  // {
  //   path: '/returns/create',
  //   name: 'returns-create',
  //   component: () => import('@/views/modules/returns/create-returns/new-return/Create.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         icon: 'CornerUpLeftIcon',
  //         active: true
  //       },
  //       {
  //         text: 'Crear devolución',
  //         active: true
  //       }
  //     ],
  //     layout: 'full',
  //     resource: 'returns',
  //     action: 'create'
  //   }
  // },
  {
    path: '/returns/list',
    name: 'returns-list',
    component: () => import('@/views/modules/returns/list-returns/List.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Listado de devoluciones',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu', // resource: 'returns',
      action: 'dashboard' // action: 'list'
    }
  },
  {
    path: '/returns/print-returns',
    name: 'print-returns',
    component: () => import('@/views/modules/returns/print-returns/PrintReturn.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/returns/:id',
    name: 'return-details',
    component: () => import('@/views/modules/returns/details/Details.vue'),
    meta: {
      breadcrumb: [
        {
          icon: 'SendIcon',
          active: true
        },
        {
          active: true,
          text: 'Devolución {id}'
        }
      ],
      layout: 'full',
      resource: 'menu', // resource: 'returns',
      action: 'dashboard' // action: 'details'
    }
  },
  {
    path: '/marketcenter',
    name: 'marketcenter',
    component: () => import('@/views/modules/marketcenter/config/ListMarketplace.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Marketcenter',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/marketcenter/:shipper_id/:marketcenter_code/:edit?',
    name: 'marketcenter-detail',
    component: () => import('@/views/modules/marketcenter/config/DetailConfig.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Marketcenter',
          active: true
        },
        {
          text: 'Canales de venta',
          active: true
        },
        {
          text: '{marketcenter_code}',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/marketcenter-deliveries',
    name: 'marketcenter-deliveries',
    component: () => import('@/views/modules/marketcenter/deliveries/TabDeliveries.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Listado de envíos',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/marketcenter-products',
    name: 'marketcenter-products',
    component: () => import('@/views/modules/marketcenter/products/ListProducts.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Listado de productos',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/marketcenter-labels',
    name: 'marketcenter-labels',
    component: () => import('@/views/modules/marketcenter/labels/TabPrincipal.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Impresión de etiquetas',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/shippers/edit/:id',
    name: 'shippers-edit',
    component: () =>
      import('@/views/modules/configuration/shippers/edit-shippers/ShippersEdit.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SendIcon',
        active: true
      },
      {
        text: 'Listado de shippers',
        active: false,
        to: { name: 'config-shippers' }
      },
      {
        active: true,
        text: 'Compañía {id}'
      }
      ],
      resource: 'shippers',
      action: 'edit'
    }
  },
  {
    path: '/pickups',
    name: 'pickups',
    component: () =>
      import('@/views/modules/pickups/admin-pickups/Pickups.vue'),
    meta: {
      breadcrumb: [{
        text: 'Retiros',
        active: true
      }],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  // Vista tab de retiros
  {
    path: '/tab-pickups',
    name: 'tab-pickups',
    component: () =>
      import('@/views/modules/deliveries/create/pickups/ListPickups.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  // Vista tab de retiros devolución
  {
    path: '/tab-return-pickups',
    name: 'tab-pickups',
    component: () =>
      import('@/views/modules/returns/return-pickups/ReturnPickupsList.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  // Vista publica cancelación de retiros
  {
    path: '/public/pickups/:uuid/cancel',
    name: 'pickups',
    component: () =>
      import('@/views/modules/pickups/public-views/CancelPickups.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  // Vista publica evaluación de retiros
  {
    path: '/public/pickups/:uuid/evaluate',
    name: 'pickups',
    component: () =>
      import('@/views/modules/pickups/public-views/EvaluatePickups.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  // Bloqueo creacion de retiros para empresas
  {
    path: '/pickups/configs/disable-companies',
    name: 'disable-companies',
    component: () =>
      import('@/views/modules/pickups-config/disable-pickups/List.vue'),
    meta: {
      breadcrumb: [{
        text: 'Desactivación de empresas',
        active: true
      }],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/users',
    name: 'config-users',
    component: () =>
      import('@/views/modules/configuration/users/Users.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Usuarios',
        active: true
      }
      ],
      resource: 'config',
      action: 'users'
    }
  },
  {
    path: '/user-permissions',
    name: 'config-users-permissions',
    component: () =>
      import('@/views/modules/configuration/users/Permissions.vue'),
    props: route => ({ permissions: route.params.permissions, role: route.params.role }),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Usuarios',
        active: false,
        to: { name: 'config-users' }
      },
      {
        text: 'Permisos {user}',
        active: true
      }
      ],
      resource: 'users',
      action: 'permissions'
    }
  },
  {
    path: '/tracking',
    name: 'config-tracking',
    component: () =>
      import('@/views/modules/configuration/tracking/Tabs.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Tracking',
        active: true
      }
      ],
      layout: 'full',
      resource: 'config',
      action: 'tracking'
    }
  },
  {
    path: '/aliases',
    name: 'config-aliases',
    component: () => import('@/views/modules/configuration/aliases/Aliases.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Alias de comunas',
          active: true
        }
      ],
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/notifications',
    name: 'config-notifications',
    component: () =>
      import('@/views/modules/configuration/notifications/Notifications.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Notificaciones',
          active: true
        }
      ],
      layout: 'full',
      resource: 'config',
      action: 'notifications'
    }
  },
  {
    path: '/folders',
    name: 'config-folders',
    component: () =>
      import('@/views/modules/configuration/folders/Folders.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Folders',
        active: true
      }
      ],
      resource: 'config',
      action: 'folders'
    }
  },
  {
    path: '/shippers',
    name: 'config-shippers',
    component: () =>
      import('@/views/modules/configuration/shippers/Shippers.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Shippers',
        active: true
      }
      ],
      resource: 'config',
      action: 'shippers'
    }
  },
  {
    path: '/addresses',
    name: 'config-addresses',
    component: () =>
      import('@/views/modules/configuration/addresses/Addresses.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Direcciones',
        active: true
      }
      ],
      resource: 'config',
      action: 'addresses'
    }
  },
  {
    path: '/organizations',
    name: 'config-organizations',
    component: () =>
      import('@/views/modules/configuration/organizations/Organization.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Direcciones',
        active: true
      }
      ],
      resource: 'config',
      action: 'organizations'
    }
  },
  {
    path: '/carriers',
    name: 'config-carriers',
    component: () => import('@/views/modules/configuration/carriers/Carriers.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Couriers',
        active: true
      }
      ],
      resource: 'config',
      action: 'carriers'
    }
  },
  {
    path: '/carriers-platforms',
    name: 'config-carriers-platforms',
    component: () => import('@/views/modules/configuration/carriers/Carriers.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Couriers',
        active: true
      }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/carriers/details/:id/:name',
    name: 'details-carriers',
    component: () =>
      import('@/views/modules/configuration/carriers/details/Details.vue'),
    meta: {
      breadcrumb: [
        {
          icon: 'SettingsIcon',
          active: true
        },
        {
          text: 'Listado de Couriers',
          active: false,
          to: { name: 'config-carriers' }
        },
        {
          active: true,
          text: 'Couriers {name}'
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/carriers/details/:id/:name/:service/transit-day',
    name: 'mantenedor-dias-transito',
    component: () => import('@/views/modules/configuration/carriers/carrierServices/dias-trafico/TransitDay.vue'),
    meta: {
      breadcrumb: [
        {
          icon: 'SettingsIcon',
          active: true
        },
        {
          text: 'Listado de Couriers',
          active: false,
          to: { name: 'config-carriers' }
        },
        {
          active: false,
          text: 'Carriers {name}',
          to: { name: 'details-carriers' }
        },
        {
          active: false,
          text: 'Servicio {service}',
          to: { name: 'details-carriers' }
        },
        {
          active: true,
          text: 'Días de tránsito'
        }
      ],
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/carriers-platforms/details/:id/:name',
    name: 'details-carriers-platforms',
    component: () =>
      import('@/views/modules/configuration/carriers/details/DetailsPlatform.vue'),
    meta: {
      breadcrumb: [
        {
          icon: 'SettingsIcon',
          active: true
        },
        {
          text: 'Listado de Couriers',
          active: false,
          to: { name: 'config-carriers-platforms' }
        },
        {
          active: true,
          text: 'Couriers {name}'
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/carriers/create',
    name: 'create-carriers',
    component: () =>
      import('@/views/modules/configuration/carriers/generals/Generals.vue'),
    meta: {
      breadcrumb: [
        {
          icon: 'SettingsIcon',
          active: true
        },
        {
          text: 'Listado de Couriers',
          active: false,
          to: { name: 'config-carriers' }
        },
        {
          active: true,
          text: 'Crear Courier'
        }
      ],
      resource: 'config',
      action: 'carriers'
    }
  },
  {
    path: '/organizations/details/:id',
    name: 'details-organizations',
    component: () => import('@/views/modules/configuration/organizations/Tabs.vue'),
    meta: {
      breadcrumb: [
        {
          icon: 'SettingsIcon',
          active: true
        },
        {
          text: 'Listado de Organizaciones',
          active: false,
          to: { name: 'config-organizations' }
        },
        {
          active: true,
          text: 'Detalle Organizacion'
        }
      ],
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/advanced-rules/:entity/:entity_id/:rule_id',
    name: 'advanced-rules-create',
    component: () =>
      import('@/views/modules/advanced-rules/create/Rule.vue'),
    meta: {
      breadcrumb: [{
        svgIcon: {
          size: '16',
          icon: 'M6 20a2 2 0 1 0-4 0a2 2 0 0 0 4 0zM16 4a2 2 0 1 0-4 0a2 2 0 0 0 4 0zm0 16a2 2 0 1 0-4 0a2 2 0 0 0 4 0zm-5-8a2 2 0 1 0-4 0a2 2 0 0 0 4 0zm10 0a2 2 0 1 0-4 0a2 2 0 0 0 4 0zM5.058 18.306l2.88-4.606m2.123-3.397l2.877-4.604m-2.873 8.006l2.876 4.6M15.063 5.7l2.881 4.61'
        },
        active: true
      },
      {
        text: 'Configurar regla',
        active: true
      }
      ],
      layout: 'full',
      resource: 'menu', // resource: 'advanced-rules',
      action: 'dashboard' // action: 'create'
      
    }
  },
  {
    path: '/advanced-rules',
    name: 'advanced-rules-list',
    component: () =>
      import('@/views/modules/advanced-rules/List.vue'),
    meta: {
      breadcrumb: [{
        svgIcon: {
          size: '16',
          icon: 'M6 20a2 2 0 1 0-4 0a2 2 0 0 0 4 0zM16 4a2 2 0 1 0-4 0a2 2 0 0 0 4 0zm0 16a2 2 0 1 0-4 0a2 2 0 0 0 4 0zm-5-8a2 2 0 1 0-4 0a2 2 0 0 0 4 0zm10 0a2 2 0 1 0-4 0a2 2 0 0 0 4 0zM5.058 18.306l2.88-4.606m2.123-3.397l2.877-4.604m-2.873 8.006l2.876 4.6M15.063 5.7l2.881 4.61'
        },
        active: true
      },
      {
        text: 'Listado reglas avanzadas',
        active: true
      }
      ],
      layout: 'full',
      resource: 'menu', // resource: 'advanced-rules',
      action: 'dashboard' // action: 'list'
    }
  },
  {
    path: '/integrations',
    name: 'integrations-list',
    component: () =>
      import('@/views/modules/integrations/List.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SlidersIcon',
        active: true
      },
      {
        text: 'Listado de integraciones',
        active: true
      }
      ],
      layout: 'full',
      resource: 'integrations',
      action: 'list'
    }
  },
  {
    path: '/create-group',
    name: 'tm-group-create',
    component: () =>
      import('@/views/modules/tickets-management/groups/create/CreateGroup.vue'),
    meta: {
      breadcrumb: [{
        icon: 'ClipboardIcon',
        active: true
      },
      {
        text: 'Crear grupo',
        active: true
      }
      ],
      resource: 'ticketManagement',
      action: 'groupCreate',
      layout: 'full'
    }
  },
  {
    path: '/groups',
    name: 'tm-group-list',
    component: () =>
      import('@/views/modules/tickets-management/groups/list/List.vue'),
    meta: {
      breadcrumb: [{
        icon: 'ClipboardIcon',
        active: true
      },
      {
        text: 'Listado de grupos',
        active: true
      }
      ],
      resource: 'ticketManagement',
      action: 'groupList',
      layout: 'full'
    }
  },
  {
    path: '/list-rules',
    name: 'tm-rule-list',
    component: () =>
      import('@/views/modules/tickets-management/rules/list/List.vue'),
    meta: {
      breadcrumb: [{
        icon: 'ClipboardIcon',
        active: true
      },
      {
        text: 'Listado de reglas',
        active: true
      }
      ],
      resource: 'ticketManagement',
      action: 'ruleList',
      layout: 'full'
    }
  },
  {
    path: '/create-rule',
    name: 'tm-rule-create',
    component: () =>
      import('@/views/modules/tickets-management/rules/create/CreateRule.vue'),
    meta: {
      breadcrumb: [{
        icon: 'ToolIcon',
        active: true
      },
      {
        text: 'Crear regla',
        active: true
      }
      ],
      resource: 'ticketManagement',
      action: 'ruleCreate',
      layout: 'full'
    }
  },
  {
    path: '/detail-rules/:id',
    name: 'tm-rule-modify',
    component: () =>
      import('@/views/modules/tickets-management/rules/create/ModifyRule.vue'),
    meta: {
      breadcrumb: [{
        icon: 'ClipboardIcon',
        active: true
      },
      {
        text: 'Listado de reglas',
        active: false,
        to: { name: 'ticket-rules' }
      },
      {
        text: 'Modificar regla',
        active: true
      }
      ],
      resource: 'ticketManagement',
      action: 'ruleModify',
      layout: 'full'
    }
  },
  {
    path: '/tickets-pending',
    name: 'tickets-pending',
    component: () => import('@/views/modules/tickets-pending/List.vue'),
    meta: {
      filter: {
        current_status: 'ISSUE_INFO_REQUESTED,COMPLAINT_INFO_REQUESTED,COMPENSATION_INFO_REQUESTED,ISSUE_DOC_REQUESTED,COMPLAINT_DOC_REQUESTED,COMPENSATION_DOC_REQUESTED,DISAGREEMENT_INFO_REQUESTED,DISAGREEMENT_DOC_REQUESTED',
        waiting_days: true,
        order_by: 'WAITING_DAYS_DESC'
      },
      breadcrumb: [
        {
          // icon: 'FileTextIcon',
          text: 'Tickets',
          active: true
        }
      ],
      layout: 'full',
      resource: 'tickets',
      action: 'pending'
    }
  },
  {
    path: '/default-answer',
    name: 'default-answer',
    component: () => import('@/views/modules/default-answer/List.vue'),
    meta: {
      filter: {},
      breadcrumb: [
        {
          text: 'Respuestas predeterminadas',
          active: true
        }
      ],
      layout: 'full',
      resource: 'tickets',
      action: 'list'
    }
  },
  {
    path: '/report-problem/:token',
    name: 'report-problem',
    component: () => import('@/views/modules/report-problem/ReportProblem.vue'),
    meta: {
      filter: {},
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () =>
      import('@/views/modules/support/Main.vue'),
    meta: {
      breadcrumb: [{
        text: 'Soporte y ayuda',
        active: true
      }],
      layout: 'full',
      resource: 'menu',
      action: 'support'
    }
  },
  {
    path: '/support/carriers',
    name: 'support-carriers',
    component: () =>
      import('@/views/modules/support/Carriers.vue'),
    meta: {
      breadcrumb: [{
        text: 'Soporte y ayuda',
        to: '/support'
      },
      {
        text: 'Couriers',
        active: true
      }
      ],
      resource: 'support',
      action: 'carriers'
    }
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () =>
      import('@/views/modules/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () =>
      import('@/views/modules/auth/Signup.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import('@/views/modules/auth/profile/Profile.vue'),
    meta: {
      resource: 'menu',
      action: 'dashboard',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/403',
    name: 'forbidden',
    component: () =>
      import('@/views/modules/others/error.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard',
      error: {
        code: 403,
        title: 'error-forbidden',
        subtitle: 'error-msg-forbidden'
      }
    }
  },
  {
    path: '/404',
    name: 'error',
    component: () =>
      import('@/views/modules/others/error.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard',
      error: {
        code: 404,
        title: 'error-not-found',
        subtitle: 'error-msg-not-found'
      }
    }
  },
  {
    path: '/web-tracking/:company?/:delivery?/:type?',
    name: 'web-tracking',
    component: () =>
      import('@/views/modules/web-tracking/WebTracking.vue'),
    meta: {
      external: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/personalize/:company?/:company_aux?/:delivery?',
    name: 'personalize',
    component: () =>
      import('@/views/modules/web-tracking/Personalize.vue'),
    meta: {
      resource: 'menu',
      action: 'personalize',
      layout: 'full',
      redirectIfLoggedIn: false
    }
  },

  {
    path: '/external-login',
    name: 'external-login',
    component: () =>
      import('@/views/modules/auth/ExternalLogin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
      noRedirect: true
    }
  },
  {
    path: '/external/:token?/:module?/:param1?/:param2?/:param3?',
    name: 'external',
    component: () =>
      import('@/views/modules/auth/External.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/billing/manage',
    name: 'billing-manage',
    component: () =>
      import('@/views/modules/billing/manage/ManageContacts.vue'),
    meta: {
      breadcrumb: [{
        text: 'Listado de empresas',
        active: true
      }],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/billing/manage/edit/:id',
    name: 'manage-edit',
    component: () =>
      import('@/views/modules/billing/manage/edit-create-manage-contacts/CreateManageContacts.vue'),
    meta: {
      breadcrumb: [{
        icon: 'FileIcon',
        active: true
      },
      {
        text: 'Listado de Empresas',
        active: true,
        to: { name: 'billing-manage' }
      },
      {
        active: true,
        text: 'Compañía {id}'
      }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/billing/manage2/edit/:id',
    name: 'manage-edit2',
    component: () =>
      import('@/views/modules/billing/manage/edit-create-manage-contacts/CreateManageContacts.vue'),
    meta: {
      breadcrumb: [{
        icon: 'FileIcon',
        active: true
      },
      {
        text: 'Listado de Empresas',
        active: true
        // to: { name: 'billing-manage' }
      },
      {
        active: true,
        text: 'Compañía {id}',
        beta: true
      }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/billing/manage/edit/:id/history',
    name: 'manage-edit-history',
    component: () =>
      import('@/views/modules/billing/manage/edit-manage-contacts-history/EditHistoryList.vue'),
    meta: {
      breadcrumb: [{
        icon: 'FileIcon',
        active: true
      },
      {
        text: 'Listado de Empresas',
        active: true
      },
      {
        active: true,
        text: 'Compañía {id}'
      },
      {
        active: true,
        text: 'Historial de cambios',
        beta: true
      }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/billing/manage/control',
    name: 'manage-billing-control',
    component: () => import('@/views/modules/billing/manage/control/List.vue'),
    meta: {
      breadcrumb: [{
        text: 'Facturación',
        active: true
      },
      {
        text: 'Control de facturación',
        active: true
      }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/billing/manage/create',
    name: 'billing-create',
    component: () =>
      import('@/views/modules/billing/manage/edit-create-manage-contacts/CreateManageContacts.vue'),
    meta: {
      breadcrumb: [{
        icon: 'FileIcon',
        active: true
      },
      {
        text: 'Listado de Empresas',
        active: true,
        to: { name: 'billing-manage' }
      },
      {
        active: true,
        text: 'Crear manejador de contactos'
      }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/billing/details/:id',
    name: 'billing-details',
    component: () =>
      import('@/views/modules/billing/list-billing-data/Tabs.vue'),
    meta: {
      breadcrumb: [{
        text: 'Detalles de facturación',
        active: true
      }],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard' //Arreglar esto para los permisos de vista
    }
  },
  {
    path: '/recaudos/:id',
    name: 'recaudos',
    component: () => import('@/views/modules/billing/list-billing-data/collections/CollectionsForPlatform.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Recaudos',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard' //Arreglar esto para los permisos de vista
    }
  },
  {
    path: '/billing/manage/:id/logs-indemnifications',
    name: 'historial-ajustes',
    component: () =>
      import('@/views/modules/billing/list-billing-data/indemnifications/HistoryLogAjust.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Detalles de facturacion',
          active: true
        },
        {
          active: false,
          text: 'Indemnizaciones',
          to: { name: 'billing-details' }
        },
        {
          active: true,
          text: 'Historial de ajustes',
          beta: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/carriers-client/:id',
    name: 'carrier-client',
    component: () =>
      import('@/views/modules/carriers-client/CarriersClient.vue'),
    meta: {
      breadcrumb: [{
        text: 'Couriers',
        active: true,
        beta: true
      }],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/materials',
    name: 'materials',
    component: () => import('@/views/modules/materials/Materials.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Solicitud de materiales',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/carriers-list',
    name: 'carriers-list',
    component: () => import('@/views/modules/carriers/CarriersList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Lista de couriers',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/carrier/:id/services',
    name: 'carrier-services',
    component: () => import('@/views/modules/carriers/CarrierServices.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Lista de couriers',
          active: false,
          to: { name: 'carriers-list' }
        },
        {
          active: true,
          text: 'Servicios del couriers'
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard',
      carrierName: ''
    }
  },
  {
    path: '/carrier/:carrier_id/services/:service_id/rates',
    name: 'carrier-service-rates',
    component: () => import('@/views/modules/carriers/CarrierServiceRates.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Lista de couriers',
          active: false,
          to: { name: 'carrier-services' }
        },
        {
          active: true,
          text: 'Servicio'
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard',
      carrierName: ''
    }
  },
  {
    path: '/carrier/:carrier_id/services/:service_id/rates/:rate_id/coverages',
    name: 'carrier-service-rate-coverages',
    component: () => import('@/views/modules/carriers/CarrierServiceRateCoverages.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Lista de couriers',
          active: false,
          to: { name: 'carriers-list' }
        },
        {
          active: true,
          text: 'Servicio'
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard',
      carrierName: ''
    }
  },
  {
    path: '/tariff-calculator',
    name: 'tariff-calculator',
    component: () => import('@/views/modules/pricing/tariff-calculator/tariffCalculator.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Tarifas y Cotizador',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/manage-tariffs',
    name: 'manage-tariffs',
    component: () => import('@/views/modules/pricing/manage-tariffs/ManageTariff.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Administrar Tarifas',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/prueba-entrega/:delivery_id',
    name: 'prueba-entrega',
    component: () =>
      import('@/views/modules/prueba-entrega/PruebaEntrega.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/warehouse/:id',
    name: 'warehouse',
    component: () => import('@/views/modules/companies/companies-id/EditCompaniesForPlatform.vue'),
    meta: {
      filter: {},
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/list-carriers',
    name: 'list-carriers',
    component: () => import('@/views/modules/configuration/carriers-config/List.vue'),
    meta: {
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Couriers',
        active: true
      }
      ],
      layout: 'full',
      resource: 'config',
      action: 'carriers'
    }
  },
  {
    path: '/list-carriers/:id_carrier',
    name: 'carrier-detail',
    component: () => import('@/views/modules/configuration/carriers-config/component/DetailCarrier.vue'),
    meta: {
      filter: {},
      breadcrumb: [{
        icon: 'SettingsIcon',
        active: true
      },
      {
        text: 'Couriers',
        active: true
      }
      ],
      layout: 'full',
      resource: 'config',
      action: 'carriers'
    }
  },
  {
    path: '/tickets-assign',
    name: 'assign-user',
    component: () => import('@/views/modules/tickets-assign/AssignTicket.vue'),
    meta: {
      filter: {},
      layout: 'full',
      resource: 'tickets',
      action: 'list'
    }
  },
  {
    path: '/priority-config',
    name: 'priority-config',
    component: () => import('@/views/modules/priority-config/List.vue'),
    meta: {
      filter: {},
      breadcrumb: [
        {
          text: 'Matriz de priorización',
          active: true
        }
      ],
      layout: 'full',
      resource: 'tickets',
      action: 'list'
    }
  },
  {
    path: '/automatic-reports',
    name: 'automatic-reports', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/automatic-reports/Reports.vue'),
    meta: {
      layout: 'full',
      resource: 'automatic-reports',
      action: '88888'
    }
  },
  {
    path: '/automatic-reports/create',
    name: 'automatic-reports-create', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/automatic-reports/CreateReport.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Reportes',
          active: false,
          icon: 'BarChart2Icon',
          to: { name: 'automatic-reports' }
        },
        {
          active: true,
          text: 'Programación de reporte'
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/automatic-reports/update/:id',
    name: 'automatic-reports-update', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/automatic-reports/CreateReport.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Reportes',
          active: false,
          icon: 'BarChart2Icon',
          to: { name: 'automatic-reports' }
        },
        {
          active: true,
          text: 'Edición de reporte'
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/pricing-web',
    name: 'pricing-web',
    component: () =>
      import('@/views/modules/pricing/pricing-web/PricingWeb.vue'),
    meta: {
      external: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/protocol-company/:id',
    name: 'protocol-company',
    component: () => import('@/views/modules/protocol-company/List.vue'),
    meta: {
      filter: {},
      layout: 'full',
      resource: 'tickets',
      action: 'list'
    }
  },
  {
    path: '/multi-account/:id',
    name: 'multi-account',
    component: () => import('@/views/modules/configuration/shippers/edit-shippers/tabs/TabMultiAccountForPlatform.vue'),
    meta: {
      filter: {},
      layout: 'full',
      resource: 'config',
      action: 'carriers'
    }
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/modules/companies/companies-list/Companies.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Listado de compañías',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies/:id/companies-extrafields',
    name: 'companies-extrafields',
    component: () => import('@/views/modules/companies/companies-id/companies-extra-fields/Main.vue'),
    meta: {
      // breadcrumb: [
      //   {
      //     text: 'Campos adicionales de Empresa',
      //     active: true
      //   }
      // ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies/:id/notifications',
    name: 'companies-notifications',
    component: () => import('@/views/modules/companies/companies-id/notifications/Main.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies/:id',
    name: 'edit-companies',
    component: () => import('@/views/modules/companies/companies-id/CompanyInfo/CompanyInfo.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies/beta/:id', //Limpiar mas adelante
    name: 'company-details',
    component: () => import('@/views/modules/companies/companies-id/mainTabs.vue'),
    meta: {
      filter: {},
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies/:id/employees',
    name: 'employed-users',
    component: () => import('@/views/modules/companies/companies-id/Employees/Employees.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/modal-new-dev',
    name: 'modal-new-dev',
    component: () => import('@/views/modules/newDevModals/DevModal.vue'),
    meta: {
      filter: {},
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/notifications/viewer/:token?/:module?/:param1?/:param2?/:param3?/:param4?/:param5?',
    name: 'viewer',
    component: () =>
      import('@/views/modules/configuration/notifications/Viewer.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/envia-flash/create',
    name: 'envia-flash', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/envia-flash/create/create.vue'),
    meta: {
      breadcrumb: [
        {
          text: ' Envía-Flash',
          active: true,
          icon: 'SendIcon',
          to: { name: 'envia-flash' }
        },
        {
          text: ' Create',
          active: true,
          to: { name: 'envia-flash' }
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/envia-flash/configuration',
    name: 'envia-flash', // El name corresponde al archivo de traducciones
    component: () => import('@/views/modules/envia-flash/config/Configuration.vue'),
    meta: {
      breadcrumb: [
        {
          text: ' Envía-Flash',
          active: true,
          icon: 'SendIcon',
          to: { name: 'envia-flash' }
        },
        {
          text: ' Configuration',
          active: true,
          to: { name: 'envia-flash' }
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/shipments-carriers',
    name: 'shipments-carriers',
    component: () => import('@/views/modules/shipments-carriers/List.vue'),
    meta: {
      filter: {},
      layout: 'carrier',
      resource: 'tickets',
      action: 'list'
    }
  },
  {
    path: '/request-carriers',
    name: 'request-carriers',
    component: () => import('@/views/modules/request-carriers/List.vue'),
    meta: {
      filter: {},
      layout: 'carrier',
      resource: 'tickets',
      action: 'list'
    }
  },
  {
    path: '/store-management/:id?',
    name: 'store-management',
    component: () => import('@/views/modules/store-management/StoreManagement'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/pudos',
    name: 'pudos',
    component: () => import('@/views/modules/pudos/Pudos.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Puntos de retiro',
          active: true
        }
      ],
      layout: 'full',
      resource: 'common',
      action: 'manage'
      /* resource: 'menu',
      action: 'dashboard' */
    }
  },
  {
    path: '/pudos/edit/:id',
    name: 'pudos-edit',
    component: () =>
      import('@/views/modules/pudos/details-pudo/DetailsPudo.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Puntos de retiro',
          active: true,
          to: { name: 'pudos' }
        },
        {
          active: true,
          text: 'Detalle {id}'
        }
      ],
      layout: 'full',
      resource: 'common',
      action: 'manage'
      /* resource: 'menu',
      action: 'dashboard' */
    }
  },
  {
    path: '/tickets-madagascar',
    name: 'tickets-madagascar',
    component: () =>
      import('@/views/modules/tickets/List.vue'),
    meta: {
      resource: 'tickets',
      action: 'detail',
      layout: 'full'
    }
  },
  {
    path: '/tickets-madagascar/:id',
    name: 'tickets-madagascar-detail',
    component: () =>
      import('@/views/modules/tickets/components/Detail/DetailTicket.vue'),
    meta: {
      // breadcrumb: [{
      //   text: 'Tickets',
      //   active: false,
      //   to: { name: 'tickets-madagascar' }
      // },
      // {
      //   text: '',
      //   active: true,
      //   detail: 'id'
      // }
      // ],
      resource: 'tickets',
      action: 'detail',
      layout: 'full'
    }
  },
  {
    path: '/tickets-madagascar-create/:id/:source',
    name: 'tickets-madagascar-create',
    component: () =>
      import('@/views/modules/tickets/components/CreateTickets/CreateViewTicket.vue'),
    meta: {
      breadcrumb: [{
        text: 'Tickets',
        active: false,
        to: { name: 'tickets-madagascar' }
      },
      {
        text: '',
        active: true,
        detail: 'id'
      }
      ],
      resource: 'tickets',
      action: 'detail',
      layout: 'full'
    }
  },
  {
    path: '/companies/:id/integrations',
    name: 'company-detail-integrations',
    component: () => import('@/views/modules/companies/companies-id/integrationsTab/TabIntegrationsCompany.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies/:id/marketplace',
    name: 'company-detail-marketplace',
    component: () => import('@/views/modules/companies/companies-id/Marketplaces/TabMarketplaceCompany.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies/:id/couriers-configuration',
    name: 'company-detail-couriers-configuration',
    component: () => import('@/views/modules/companies/companies-id/couriers-configuration/TabForPlatform.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/tickets-manifest',
    name: 'tickets-manifest',
    component: () =>
      import('@/views/modules/manifest/List.vue'),
    meta: {
      resource: 'tickets',
      action: 'detail',
      layout: 'full'
    }
  },
  {
    path: '/summaries/:id/logs',
    name: 'tickets-manifest',
    component: () =>
      import('@/views/modules/manifest/components/SumaryLogsTable.vue'),
    meta: {
      resource: 'tickets',
      action: 'detail',
      layout: 'full'
    }
  },
  {
    path: '/prove-rules',
    name: 'prove-rules',
    component: () =>
      import('@/views/modules/prove-rules/ProveRules.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  }, {
    path: '/team',
    name: 'team',
    component: () =>
      import('@/views/modules/team/Team.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/companies-new/:id',
    name: 'edit-companies-new',
    component: () => import('@/views/modules/companies/companies-id/CompanyInfo/CompanyInfoNew.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/restore-password/:user?/:param1?/:param2?',
    name: 'restore-password',
    component: () =>
      import('@/views/modules/auth/RestorePassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/companies-freemium/:id',
    name: 'edit-companies-freemium',
    component: () =>
      import('@/views/modules/companies/companies-id/CompanyInfo/CompanyInfoFreemium.vue'),
    meta: {
      /* layout: 'full', */
      resource: 'menu',
      action: 'dashboard',
      breadcrumb: [{
        text: 'Mi empresa',
        active: true
      }]
    }
  },
  {
    path: '/custom-login',
    name: 'custom-login',
    component: () => import('@/views/modules/main/Dashboard.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Pruebas de logueo',
          active: true
        }
      ],
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/test-alerts',
    name: 'testAlerts',
    component: () => import('@/views/modules/test-alerts/TestAlerts.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },  
  {      
    path: '/login-meli',
    name: 'auth-login-meli',
    component: () => import('@/views/modules/auth/LoginMeli.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/form-meli/:param1?',
    name: 'form-meli',
    component: () => import('@/views/modules/auth/FormMeli.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/manage-tariffs',
    name: 'manage-tariffs',
    component: () => import('@/views/modules/pricing/manage-tariffs/ManageTariff.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Administrar Tarifas',
          active: true
        }
      ],
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  },
  {
    path: '/envia-track',
    name: 'enviaTrack',
    component: () => import('@/views/modules/envia-track/EnviaTrackMobile.vue'),
    meta: {
      layout: 'full',
      resource: 'menu',
      action: 'dashboard'
    }
  }
]
