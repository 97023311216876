import { environment } from '@/environments/environment'

export default [
  { name: 'getShippers', url: `${environment.dubaiApiUrl}/shippers`, method: 'get' },
  { name: 'getShipper', url: `${environment.dubaiApiUrl}/shippers/{shipper_id}`, method: 'get' },
  { name: 'saveShipper', url: `${environment.dubaiApiUrl}/shippers`, method: 'post' },
  { name: 'getShipper', url: `${environment.dubaiApiUrl}/shippers/{shipper_id}`, method: 'get' },
  { name: 'deleteShipper', url: `${environment.dubaiApiUrl}/shippers/{shipper_id}`, method: 'delete', notCancel: true },
  { name: 'updateShipper', url: `${environment.dubaiApiUrl}/shippers/{shipper_id}`, method: 'put' },
  { name: 'getAddressShipper', url: `${environment.dubaiApiUrl}/shippers/{shipper_id}/addresses/{id}`, method: 'get' },
  { name: 'getAuthorizedCarriersShippers', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-shippers`, method: 'get' },
  { name: 'createAuthorizedCarriersShipper', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-shippers`, method: 'post' },
  { name: 'createAuthorizedCarriersShipperServices', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-shippers-services`, method: 'post' },
  { name: 'updateAuthorizedCarriersShipper', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-shippers/{id}`, method: 'put' },
  { name: 'updateAuthorizedCarriersShipperServices', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-shippers-services/{id}`, method: 'put' },
  { name: 'deleteAuthorizedCarriersShipper', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-shippers/{id}`, method: 'delete', notCancel: true },
  { name: 'getCarriersOrganizationShippers', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-organization-shippers`, method: 'get' },
  { name: 'getShipperRules', url: `${environment.tokyoApiUrl}/v1/carriers/shipper-account-rules`, method: 'get' },
  { name: 'createShipperRules', url: `${environment.tokyoApiUrl}/v1/carriers/shipper-account-rules`, method: 'post' },
  { name: 'updateCarriersAccount', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-organization-shippers/{account_id}`, method: 'put' },
  { name: 'updateShipperRules', url: `${environment.tokyoApiUrl}/v1/carriers/shipper-account-rules/{rules_code}`, method: 'put' },
  { name: 'createCarriersAccount', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-organization-shippers`, method: 'post' },
  { name: 'deleteCarriersAccount', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-organization-shippers/{account_id}`, method: 'delete', notCancel: true },
  { name: 'deleteShipperRules', url: `${environment.tokyoApiUrl}/v1/carriers/shipper-account-rules/{account_id}`, method: 'delete', notCancel: true },
  { name: 'getOrganizationCarrierContract', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-organizations/carrier/{carrier_id}/contract/{contract_id}`, method: 'get' },
  { name: 'getCarrierOrganizationHistory', url: `${environment.odaibaApiUrl}/v1/carriers/account-change-logs`, method: 'get' },
  { name: 'getAccountsFlex', url: `${environment.tokyoStationApiUrl}/v1/stations/accounts`, method: 'get' },
  { name: 'postAccountFlex', url: `${environment.tokyoStationApiUrl}/v1/stations/accounts`, method: 'post'},
  { name: 'getWarehousesApiPlatformPucon', url: `${environment.apiPlatform}/v1/pucon/companies/{shipper_id}/warehouses`, method: 'get' },
  { name: 'putMkpStateRule', url: `${environment.nippurApiUrl}/organization/{organization_id}/rule/{rule_id}/{method}`, method: 'put' },
  { name: 'putSellerStateRule', url: `${environment.nippurApiUrl}/organization/{organization_id}/shipper/{shipper_id}/rule/{rule_id}/{method}`, method: 'put' },
  { name: 'putAccountFlex', url: `${environment.tokyoStationApiUrl}/v1/stations/accounts/{account_id}`, method: 'put' }
]
