<template>
  <div>
    <span v-for="(action, index) in myActions" :key="index">
      <span class="px-05 cursor-pointer hover-text-special" :class="`hvr text-${action.color}`"  @click="() => executeAction(action, data)"
      v-if="isActionVisible(action, data)"
      >
        <span v-if="!action.typeIcon || action.typeIcon === 'feather'">
        <feather-icon :icon="generateIconClasses(action, data).icon" :class="[generateIconClasses(action, data).classes, action.iconClass]"
          v-b-tooltip.hover
          :title="$t(action.dualState ? (action.switchOwnId && data.item[action.switchOwnId] ? action.dualState.on || '' : action.dualState.off || '') : (action.text || ''))"/>
          </span>
        <span v-if="action.typeIcon === 'fa' || action.typeIcon === 'far' || action.typeIcon === 'fas'">
        <font-awesome-icon :icon="[action.typeIcon, action.icon]"/>
        </span>

        <span v-if="action.component">
          <component :is="action.component" :data="data.item" :action="action.action" :key="index"/>
        </span>
      </span>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'table-render-actions',
  props: ['actions', 'data'],
  data () {
    return {
      myActions: []
    }
  },
  computed: {
    ...mapGetters({
      modularPermissions: 'getModularPermissions'
    })
  },
  watch: {
    modularPermissions () {
      this.myActions = this.$filterUsingPermission(this.actions, this.modularPermissions)
    },
    actions() {
      this.myActions = this.$filterUsingPermission(this.actions, this.modularPermissions)
    }
  },
  mounted() {
    this.myActions = this.$filterUsingPermission(this.actions || [], this.modularPermissions)
  },
  methods: {
    generateIconClasses (action, data) {
      const obj = {
        classes: '',
        icon: ''
      }
      if (action.switchOwnId) {
        if (!(data.item[action.switchOwnId] === true || data.item[action.switchOwnId] === false)) {
          obj.classes += 'spinner'
          obj.icon = 'LoaderIcon'
          return obj
        }
        if (data.item[action.switchOwnId]) {
          obj.classes += 'is-switch-true '
          if (action.dualState && action.dualState.iconOn) {
            obj.icon = action.dualState.iconOn
          }
        }
        if (!(action.switchOwnId && data.item[action.switchOwnId])) {
          obj.classes += 'is-switch '
          if (action.dualState && action.dualState.iconOff) {
            obj.icon = action.dualState.iconOff
          }
        }
      } else {
        obj.icon += action.icon
      }
      return obj
    },
    /**
     * Determina si una acción es visible o no.
     * Se puede definir una dependencia o una función de visibilidad,
     * si no se define ninguna de las dos, la acción es visible por defecto.
     * Creería que es mejor deprecar la propiedad dependency y usar visible
     * porque es más flexible
     * @param {Object} action Definición del objeto de acción
     * @param {*} data Datos de la fila
     */
    isActionVisible(action, data) {
      const { dependency, visible } = action
      if (dependency) return data.item[dependency]
      if (visible) return visible(data.item)
      return true
    },

    /**
     * Ejecuta la acción correspondiente si es que
     * la acción no está deshabilitada
     * @param {*} action Definición del objeto de acción
     * @param {*} data Datos de la fila
     */
    executeAction(action, data) {
      if (action.disabled) return

      if (data.item) {
        action.action(data.item.id)
      } else {
        action.action(data.rowdata.id)
      }
    }
  }
}
</script>
<style lang="">

</style>
