import Vue from 'vue'
import VueRouter from 'vue-router'
import { environment } from '@/environments/environment'

// Routes
import { canNavigate, matchRoleUrl } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { loadLanguageAsync } from '@/libs/i18n'
import enviame from './routes/enviame'
// import apps from './routes/apps' !!!!Listo
// import dashboard from './routes/dashboard' !!!!Listo
// import uiElements from './routes/ui-elements/index' !!!!Listo
// import pages from './routes/pages' !!!!Listo
// import chartsMaps from './routes/charts-maps' !!!!Listo
// import formsTable from './routes/forms-tables' !!!!Listo
// import others from './routes/others' !!!!Listo

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '', redirect: { name: environment.redirectViewHome ? environment.redirectViewHome : 'dashboard' } },
    { path: '/', redirect: { name: environment.redirectViewHome ? environment.redirectViewHome : 'dashboard' } },
    // ...apps,
    // ...dashboard,
    // ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...enviame,
    {
      path: '*',
      redirect: { name: 'error' }
    }
  ]
})

/**
   * redirectToPath se creo para manejar el redireccionado luego del login,
   * cuando este migrado todo el proyecto se debe mejorar,
   * ya que no sera más un iframe
   */
export let redirectToPath = null

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // const lang = to.params.lang
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      redirectToPath = to.path
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'forbidden' })
  }
  // Redirect if logged in

  const userData = getUserData()
  //check if the role or permission  match with the url
  if (!!userData && userData.role === 'carrier' && to.fullPath !== '/403' && !matchRoleUrl(userData.role, to.fullPath)) next({ name: 'forbidden' })

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  loadLanguageAsync(to.name)
  // return loadLanguageAsync(to.name).then(() => next())
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from, next) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading && (!from || !from.name || from.name.indexOf('auth-') === -1)) {
    appLoading.style.display = 'none'
  }
})

export default router
