import BaseServices from '@/store/services/index'
const baseService = new BaseServices()

export default {
  state: {
    getCountriesQuoter: [],
    getPlacesQuoter: [],
    getServiceTypesQuoter: [],
    getPricingQuoter: [],
    getInfoCarrier: [],
    getPendingActivities: [],
    getActivityRates: []
  },

  getters: {
    getCountriesQuoter: (state) => state.getCountriesQuoter,
    getPlacesQuoter: (state) => state.getPlacesQuoter,
    getServiceTypesQuoter: (state) => state.getServiceTypesQuoter,
    getPricingQuoter: (state) => state.getPricingQuoter,
    getInfoCarrier: (state) => state.getInfoCarrier,
    getPendingActivities: state => state.getPendingActivities,
    getActivityRates: state => state.getActivityRates
  },

  mutations: {
    setCountriesQuoter(state, val) {
      state.getCountriesQuoter = {
        rows: val.rows.map((el) => ({ ...el, text: el.name }))
      }
    },
    setPlacesQuoter(state, val) {
      if (val) {
        state.getPlacesQuoter = {
          rows: val.places.map((el) => ({ ...el, text: el.name }))
        }
      } else {
        state.getPlacesQuoter = {
          rows: []
        }
      }
    },
    setServiceTypesQuoter(state, val) {
      if (val) {
        state.getServiceTypesQuoter = {
          rows: val.data.map(el => ({...el, text: el.name}))
        }
      } else {
        state.getServiceTypesQuoter = {
          rows: []
        }
      }
    },
    setPricingQuoter(state, val) {
      if (val) {
        state.getPricingQuoter = {
          rows: val.data
        }
      } else {
        state.getPricingQuoter = {
          rows: []
        }
      }
    },
    setInfoCarrier(state, val) {
      if (val) {
        state.getInfoCarrier = {
          rows: val.data
        }
      } else {
        state.getInfoCarrier = {
          rows: []
        }
      }
    },
    setPendingActivities(state, val) {
      state.getPendingActivities = {
        rows: val.data
      }
    },
    setActivityRates(state, val) {
      state.getActivityRates = {
        rows: val.data
      }
    }
  }
}
