import BaseServices from '@/store/services/index'
const baseService = new BaseServices()

export default {
  state: {
    couriers: [],
    carriers: [],
    actions: [],
    serviceType: [],
    carrierStatus: [],
    carrier: {},
    servicesCarrier: [],
    trackingCarriers: {},
    carriersByShipper: [],
    carriersByOrganization: [],
    carrierConfigurations: [],
    carrierConfigurationsRequest: [],
    contractType: [],
    carrierProducts: [],
    deliveyPrueba: [],
    deliveryPruebaError: false,
    carriersOrganizationShippers: [],
    carriersOrganizationHistory: [],
    pickupType: [],
    schedulingType: [],
    clasificationServices: [],
    credentialsCourier: [],
    carriersAccountLogs: [],
    indexTabMultiAccounts: 0,
    allCarriers: [],
    shipperInfo: {}
  },
  getters: {
    getCouriers: state => state.couriers,
    getCarriers: state => state.carriers,
    getCarriersByShipper: state => state.carriersByShipper,
    getCarrierStatus: state => state.carrierStatus,
    getCarriersByOrganization: state => state.carriersByOrganization,
    getTrackingCarriers: state => state.trackingCarriers,
    getCarrier: state => state.carrier,
    getServiceCarriers: state => state.servicesCarrier,
    getServiceType: state => state.serviceType,
    getPickupType: state => state.pickupType,
    getSchedulingType: state => state.schedulingType,
    getActions: state => state.actions,
    getCarrierConfigurations: state => state.carrierConfigurations,
    getCarrierConfigurationsRequest: state => state.carrierConfigurationsRequest,
    getCarriersCountries: state => state.countries,
    getContractType: state => state.contractType,
    getCarrierProducts: state => state.carrierProducts,
    getDeliveyPrueba: state => state.deliveyPrueba,
    getCarriersOrganizationShippers: state => state.carriersOrganizationShippers,
    getClasificationServices: state => state.clasificationServices,
    getDeliveryPruebaError: state => state.deliveryPruebaError,
    getCredentialsCourier: state => state.credentialsCourier,
    getCarriersAccountLogs: state => state.carriersAccountLogs,
    getIndexTabMultiAccounts: state => state.indexTabMultiAccounts,
    getAllCarriers: state => state.allCarriers,
    getShipperInfo: state => state.shipperInfo
  },
  mutations: {
    setCouriers(state, val) {
      state.couriers = val.carriers.map((carrier, index) => {
        return {
          ...carrier,
          text: carrier.name,
          attributes: carrier.attributes.map((attribute, index) => { return { ...attribute, text: attribute.name, id: index } }),
          services: carrier.services.map((service, index) => { return { ...service, text: service.name } }),
          countries: carrier.countries.map(country => { return { ...country, text: country.name } }),
          types: carrier.types.map(type => { return { ...type, text: type.name } })
        }
      })
      state.shipperInfo = val.shipper
    },
    setCarriersByShipper(state, val) {
      state.carriersByShipper = Object.keys(val.associated_carriers).map(key => {
        return {
          ...val.associated_carriers[key],
          text: val.associated_carriers[key].carrier_name,
          code: val.associated_carriers[key].carrier_code,
          id: val.associated_carriers[key].carrier_id
        }
      })
    },
    setCarriersByOrganization(state, val) {
      state.carriersByOrganization = Object.keys(val.associated_carriers).map(key => {
        return {
          ...val.associated_carriers[key],
          text: val.associated_carriers[key].carrier_name,
          code: val.associated_carriers[key].carrier_code,
          id: val.associated_carriers[key].carrier_id
        }
      })
    },
    setCarriers(state, val) {
      state.carriers = {
        rows: val.rows.map(carrier => {
          return {
            ...carrier,
            country_carrier: carrier.country?.name
          }
        }),
        page: val.pagination.current_page,
        total: val.pagination.total,
        limit: val.pagination.total_pages
      }
    },
    setCarrier(state, val) {
      state.carrier = {
        ...val,
        country_carrier: { code: val.country?.code, text: val.country?.name }
      }
    },
    setServicesCarrier(state, val) {
      state.servicesCarrier = {
        rows: val.rows.map(service => {
          return { ...service }
        })
      }
    },
    setTrackingCarriers(state, val) {
      state.trackingCarriers = {
        rows: val.rows.map(carrier => {
          return {
            ...carrier
          }
        }),
        page: val.pagination.current_page,
        total: val.pagination.total_page,
        limit: val.pagination.current_page
      }
    },
    setActions(state, val) {
      state.actions = val.options.map(value => ({ id: value.id, text: value.name.toLowerCase(), value: value.value }))
    },
    setCarrierStatus(state, val) {
      state.carrierStatus = val.data.map(value => ({ id: value.id, text: value.name }))
    },
    setServiceType(state, val) {
      state.serviceType = val.options.map(value => ({ id: value.id, text: value.name }))
    },
    setPickupType(state, val) {
      state.pickupType = val.options.map(value => ({ id: value.id, text: value.name }))
    },
    setSchedulingType(state, val) {
      state.schedulingType = val.options.map(value => ({ id: value.id, text: value.name }))
    },
    setCarrierConfigurations(state, val) {
      state.carrierConfigurations = val.data
    },
    setCarrierConfigurationsRequest(state, val) {
      state.carrierConfigurationsRequest = val.data
    },
    setContractType(state, val) {
      state.contractType = val.options.map(value => ({ id: value.id, text: value.name }))
    },
    setCarrierProducts(state, val) {
      state.carrierProducts = val.data
    },
    setDeliveyPrueba(state, val) {
      state.deliveyPrueba = val
    },
    setDeliveryPruebaError(state, val) {
      state.deliveryPruebaError = val
    },
    setCarriersOrganizationShippers(state, val) {
      state.carriersOrganizationShippers = {
        rows: val.data,
        page: val.pagination.current_page,
        total: val.pagination.total,
        limit: val.pagination.total_pages
      }
    },
    setCarrierOrganizationHistory(state, val) {
      state.carriersOrganizationHistory = val.rows
    },
    setClasificationServices(state, val) {
      state.clasificationServices = val.service_classifications_list
    },
    setCredentialsCourier(state, val) {
      state.credentialsCourier = val.data
    },
    setCarriersAccountLogs(state, val) {
      state.carriersAccountLogs = {
        rows: val.rows,
        pagination: {
          page: val.pagination.current_page,
          total: val.pagination.total,
          limit: val.pagination.rows_per_page
        }
      }
    },
    setIndexTabMultiAccounts(state, val) {
      state.indexTabMultiAccounts = val
    },
    setAllCarriers (state, val) {
      state.allCarriers = val
    }
  },
  actions: {
    fetchServiceType({ commit }, { queryParams, params } = {}) {
      baseService
        .callService('getServiceType', queryParams, params)
        .then(response => {
          const data = response.data.data
          commit('setServiceType', data)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', {
            text: 'msg-problema-cargar-datos',
            code: err,
            type: 'alert'
          })
        })
    },
    fetchCarrierConfigurations({ commit }, { queryParams, params } = {}) {
      baseService
        .callService('getCarrierConfigurations', queryParams, params)
        .then(response => {
          const data = response.data
          commit('setCarrierConfigurations', data)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', {
            text: 'msg-problema-cargar-datos',
            code: err,
            type: 'alert'
          })
        })
    },
    fetchCarrierConfigurationsRequest({ commit }, { queryParams, params } = {}) {
      baseService
        .callService('getCarrierConfigurationsRequest', queryParams, params)
        .then(response => {
          const data = response.data
          commit('setCarrierConfigurationsRequest', data)
        })
    },
    fetchContractType({ commit }, { queryParams, params } = {}) {
      baseService
        .callService('getContractType', queryParams, params)
        .then(response => {
          const data = response.data.data
          commit('setContractType', data)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', {
            text: 'msg-problema-cargar-datos',
            code: err,
            type: 'alert'
          })
        })
    },
    fetchCarrierProducts({ commit }, { queryParams, params } = {}) {
      baseService
        .callService('getCarrierProducts', queryParams, params)
        .then(response => {
          const data = response.data
          commit('setç', data)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', {
            text: 'msg-problema-cargar-datos',
            code: err,
            type: 'alert'
          })
        })
    },
    async fetchDeliveryPrueba({ commit }, { queryParams, params, forceTracking } = {}) {
      try {
        // Indicar que la carga está en curso
        commit('setLoading', { name: 'getDeliveyPrueba', status: true })

        if (forceTracking) {
          // Llamar al servicio de fuerza de seguimiento
          await baseService.callService('forceTracking', queryParams, params)
        }

        // Llamar al servicio de obtención de prueba de entrega y obtener la respuesta
        const deliveryPruebaResponse = await baseService.callService('getDeliveyPrueba', queryParams, params)

        // Extraer los datos de la respuesta
        const data = deliveryPruebaResponse.data

        // Comprobar si existe una prueba de entrega
        if (data.delivery_proof) {
          // Actualizar el estado con los datos de la prueba de entrega
          commit('setDeliveyPrueba', data)
          // Indicar que no hay error en la entrega
          commit('setDeliveryPruebaError', false)
        } else {
          // Indicar que hay un error en la entrega
          commit('setDeliveryPruebaError', true)
        }
      } catch (err) {
        // En caso de error, indicar que hay un error en la entrega
        commit('setDeliveryPruebaError', true)
        // Configurar un mensaje de error con detalles del error
        commit('setMessage', {
          text: err.message,
          code: err.message || 'Error desconocido',
          type: 'alert'
        })
      } finally {
        // Indicar que la carga ha finalizado, independientemente del resultado
        commit('setLoading', { name: 'getDeliveyPrueba', status: false })
      }
    },
    fetchCarriersOrganizationShippers({ commit, dispatch }, { queryParams, params, onError } = {}) {

      const { shipper_id, ...rest } = queryParams

      baseService
        .callService('getCarriersOrganizationShippers', queryParams, params)
        .then(response => {
          if (response.data.length > 0) {
            commit('setCarriersOrganizationShippers', response)
          } else {
            dispatch('fetchService', {
              name: 'getCarriersOrganizationShippers',
              queryParams: {
                ...rest,
                organization_id: shipper_id
              }
            })
          }
        }).catch(err => {
          if (onError) onError(err)
          commit('setMessage', {
            text: 'msg-problema-cargar-datos',
            code: err,
            type: 'alert'
          })
        }
        )
    },
    dispatchIndexTabsMultiAccounts({ commit }, val) {
      commit('setIndexTabMultiAccounts', val)
    }
  }
}
