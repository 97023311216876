export default {
  state: {
    margins: [],
    marginsHistory: []
  },
  getters: {
    getMargins: state => state.margins,
    getMarginsHistory: state => state.marginsHistory
  },
  mutations: {
    setMargins(state, val) {
      state.margins = {
        rows: val.rows.map(el => {
          if (el.formatted_upper_limit) {
            el.upper_limit_label = el.formatted_upper_limit.includes('Hasta') ? 'Hasta' : 'Sobre'
            el.upper_limit = el.formatted_upper_limit.includes('Sobre') ? 'Rango maximo' : el.upper_limit
          }
          return el
        }),
        meta: val.meta
      }
    },
    setMarginsHistory(state, val) {
      state.marginsHistory = {
        rows: val.rows.filter(el => el.margin_type !== 'DEFAULT_BASE').map(el => {
          el.number = (val.rows.length - val.rows.indexOf(el)).toFixed(1)
          if (el.margin_type === 'DEFAULT_COUNTRY') {
            el.type = 'País'
          } else if (el.margin_type === 'DEFAULT_CARRIER') {
            el.type = 'Courier'
          } else if (el.margin_type === 'CARRIER_SERVICE') {
            el.type = 'Servicio'
          }
          if (el.validity_type === 'current') {
            el.validity = 'Actual'
          } else if (el.validity_type === 'future') {
            el.validity = 'Programada'
          } else if (el.validity_type === 'expired') {
            el.validity = 'Expirada'
          }
          el[`${el.validity_type}/${el.margin_type}`] = `${el.validity_type}/${el.margin_type}`
          return el
        }),
        meta: val.meta
      }
    }
  }
}
