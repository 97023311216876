export default {
  state: {
    getCountriesOrigins: [],
    getCountriesOriginsModal: [],
    getRatesOrigins: [],
    getServicesOrigins: [],
    deleteRate: [],
    getCarriersOrigins: [],
    getServicesModalOrigins: [],
    getAdditionalCharges: [],
    getInternationalRates: [],
    deleteInternationalRate: []
  },

  getters: {
    getCountriesOrigins: state => state.getCountriesOrigins,
    getCountriesOriginsModal: state => state.getCountriesOriginsModal,
    getRatesOrigins: state => state.getRatesOrigins,
    getServicesOrigins: state => state.getServicesOrigins,
    deleteRate: state => state.deleteRate,
    getAdditionalCharges: state => state.getAdditionalCharges,
    getInternationalRates: state => state.getInternationalRates,
    deleteInternationalRate: state => state.deleteInternationalRate,
    getCarriersOrigins: state => state.getCarriersOrigins,
    getServicesModalOrigins: state => state.getServicesModalOrigins
  },

  mutations: {
    setCountriesOrigins(state, val) {
      state.getCountriesOrigins = {
        rows: val.rows.map(el => ({...el, text: el.name}))
      }
    },
    setCountriesOriginsModal(state, val) {
      state.getCountriesOriginsModal = {
        rows: val.rows.map(el => ({...el, text: el.name}))
      }
    },
    setRatesOrigins(state, val) {
      if (val) {
        state.getRatesOrigins = {
          rows: val.data
        }
      } else {
        state.getRatesOrigins = {
          rows: []
        }
      }
    },
    setServicesOrigins(state, val) {
      if (val) {
        state.getServicesOrigins = {
          rows: val.data.map(el => ({...el, text: el.name}))
        }
      } else {
        state.getServicesOrigins = {
          rows: []
        }
      }
    },
    setCarriersOrigins(state, val) {
      if (val) {
        state.getCarriersOrigins = {
          rows: val.data.map((el) => ({...el, text: el.name}))
        }
      } else {
        state.getCarriersOrigins = {
          rows: []
        }
      }
    },
    setServicesModalOrigins(state, val) {
      if (val) {
        state.getServicesModalOrigins = {
          rows: val.data
        }
      } else {
        state.getServicesModalOrigins = {
          rows: []
        }
      }
    },
    setDeleteRate(state, val) {
      state.deleteRate = val
    },
    setAdditionalCharges(state, val) {
      state.getAdditionalCharges = {
        rows: val.rows.map(el => ({...el, text: el.name})),
        meta: val.meta
      }
    },
    setInternationalRates(state, val) {
      state.getInternationalRates = {
        rows: val.data
      }
    },
    setDeleteInternationalRate(state, val) {
      state.deleteInternationalRate = val
    }
  }
}

