module.exports = {
  environment: {
    production: false,
    apiUrl: 'https://seul-proxy-espv2-ys53qirwqq-uc.a.run.app',
    platformUrl: 'https://sandbox.enviame.io',
    puconApiUrl: 'https://stage.front.pucon.enviame.io/v1',
    estambulApiUrl: 'https://stage.front.estambul.enviame.io/v1',
    madagascarApiUrl: 'https://stage.madagascar.enviame.io/v1',
    santiagoApiUrl: 'https://stage.api.enviame.io/api/s2/v2',
    torontoApiUrl: 'https://stage.front.toronto.enviame.io/v1',
    recaptchaKey: '6Lf2vLUcAAAAAB2-9n2pw6xUILb9OkVQFrCD2EZX',
    beijingApiUrl: 'https://stage.front.beijing.enviame.io/v1',
    nippurApiUrl: 'https://stage.front.nippur.enviame.io/v1',
    pricingUrl: 'https://api-frontend-5k3oje3acq-uc.a.run.app/v1',
    dubaiApiUrl: 'https://stage.front.dubai.enviame.io/v1',
    newyorkApiUrl: 'https://stage.front.new-york.enviame.io',
    ottawaApiUrl: 'https://stage.front.ottawa.enviame.io/v1',
    ottawaMeliRedirect: 'https://stage.ottawa.enviame.io/v1',
    tokyoApiUrl: 'https://stage.front.tokyo.enviame.io/api',
    tokyoStationApiUrl: 'https://stage.front-station.tokyo.enviame.io/api',
    enviameApiUrl: 'https://stage.api.enviame.io',
    shanghaiApiUrl: 'https://stage.front.shanghai.enviame.io/v1',
    dementorsApiUrl: 'https://stage.front.sydney.enviame.io/v1',
    apiKey: 'AIzaSyC60MVfHinclp1ApT-URgLBilO5y6Ymwc8',
    authDomain: 'madagascar-stage.firebaseapp.com',
    projectId: 'madagascar-stage',
    storageBucket: 'madagascar-stage.appspot.com',
    messagingSenderId: '900384012981',
    appId: '1:900384012981:web:0a5676b3b0347db15a1cfc',
    newyorkDocsCloud: 'https://storage.cloud.google.com/new-york-billing-frontend-templates-stage',
    sfApiUrl: 'https://stage.san-francisco.enviame.io/v1',
    apiPlatform: 'https://stage.api-app.enviame.io',
    apiKeyMap: 'AIzaSyDc73UYIuXkYxmb4lv00qrSmCvyl4RHMnY',
    entelApiUrl: 'https://stage.front.entel.enviame.io/v1',
    odaibaApiUrl: 'https://odaiba-dot-tokyo-stage.uc.r.appspot.com/api',
    dubaiDocsCloud: 'https://storage.cloud.google.com/dubai-ms-stage.appspot.com',
    miamiApiUrl: 'https://stage.front.miami.enviame.io',
    buenosairesApiUrl: 'https://stage.front.buenos-aires.enviame.io',
    dashboardStudio: 'https://lookerstudio.google.com',
    minatoApiUrl: 'https://stage.front-minato.tokyo.enviame.io'
  }
}
