import Swal from 'sweetalert2'

function autofocus(type) {
  // Esto funciona siempre y cuando el dialog se posicione al principio en el DOM
  // En caso contrario, habría que añadir una clase diferente a los modals que sean creados por etiquetas y diferenciarlos en la query
  setTimeout(() => {
    document.querySelector(`.vs-dialog > footer > button.vs-dialog-accept-button.vs-button-${type}`).focus()
  }, 50)
}
function evaluateOption(result, onConfirm, onClose) {
  if (result.isConfirmed) {
    if (onConfirm) onConfirm(result.value)
  } else if (
    /* Read more about handling dismissals below */
    result.dismiss === Swal.DismissReason.cancel
  ) {
    if (onClose) onClose()
  }
}
export default function (Vue) {
  Vue.prototype.$confirm = (text, action, title, acceptText, cancelText, outsideClick = true, onClose = null, width, input, inputLabel, inputPlaceholder) => {
    Swal.fire({
      width: width || 'default',
      icon: 'warning',
      title: title || 'Confirmar',
      html: text,
      input: input || null,
      inputLabel: inputLabel || null,
      inputPlaceholder: inputPlaceholder || null,
      // didOpen: () => {
      //   const b = Swal.getHtmlContainer().querySelector('pre.pre')
      //   b.textContent = 'laaaaaaaaaaaaaaaaaaaaa'
      // },
      allowOutsideClick: outsideClick,
      focusConfirm: true,
      showCancelButton: true,
      confirmButtonText: `${acceptText || 'Aceptar'}`,
      cancelButtonText: cancelText || 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-customyesno-success',
        cancelButton: 'btn btn-customyesno-danger mr-1'
      },
      buttonsStyling: false
    }).then(result => {
      evaluateOption(result, action, onClose)
    })
  }
  Vue.prototype.$alert = (text, action, title, acceptText, outsideClick = true, colorBtnConfirm) => {
    Swal.fire({
      icon: 'error',
      title: title || 'Alerta',
      html: text,
      // didOpen: () => {
      //   const b = Swal.getHtmlContainer().querySelector('pre.pre')
      //   b.textContent = 'laaaaaaaaaaaaaaaaaaaaa'
      // },
      focusConfirm: true,
      allowOutsideClick: outsideClick,
      confirmButtonText: `${acceptText || 'Aceptar'}`,
      customClass: {
        confirmButton: colorBtnConfirm || 'btn btn-outline-danger'
      },
      buttonsStyling: false
    }).then(result => {
      evaluateOption(result, action)
    })
  }
  Vue.prototype.$success = (text, action, title, acceptText, icon, width, btnConfirm, outsideClick = true) => {
    Swal.fire({
      width: width || 'default',
      icon: icon || 'success',
      title: title || 'Completado',
      html: text,
      focusConfirm: true,
      allowOutsideClick: outsideClick,
      confirmButtonText: `${acceptText || 'Aceptar'}`,
      customClass: {
        confirmButton: btnConfirm || 'btn btn-outline-success'
      },
      buttonsStyling: false
    }).then(result => {
      evaluateOption(result, action)
    })
  }
  Vue.prototype.$info = (text, action, title, acceptText) => {
    Swal.fire({
      icon: 'info',
      title: title || 'Completado',
      html: text,
      // didOpen: () => {
      //   const b = Swal.getHtmlContainer().querySelector('pre.pre')
      //   b.textContent = 'laaaaaaaaaaaaaaaaaaaaa'
      // },
      focusConfirm: true,
      confirmButtonText: `${acceptText || 'Aceptar'}`,
      customClass: {
        confirmButton: 'btn btn-outline-info'
      },
      buttonsStyling: false
    }).then(result => {
      evaluateOption(result, action)
    })
  }
  Vue.prototype.$warning = (text, action, title, acceptText, colorBtnConfirm) => {
    Swal.fire({
      icon: 'warning',
      title: title || 'Completado',
      html: text,
      // didOpen: () => {
      //   const b = Swal.getHtmlContainer().querySelector('pre.pre')
      //   b.textContent = 'laaaaaaaaaaaaaaaaaaaaa'
      // },
      focusConfirm: true,
      confirmButtonText: `${acceptText || 'Aceptar'}`,
      customClass: {
        confirmButton: colorBtnConfirm || 'btn btn-outline-warning'
      },
      buttonsStyling: false
    }).then(result => {
      evaluateOption(result, action)
    })
  }
  Vue.prototype.$yesno = (text, action, title) => {
    Vue.prototype.$confirm(text, action, title || 'Confirmar', 'Sí', 'No')
  }

  Vue.prototype.$loadingAlert = ({ text, action, position, iconType, color }) => {
    Swal.fire({
      position: position || 'bottom-start',
      icon: iconType || false,
      html: `<span>${text || 'Cargando...'}</span>`,
      showConfirmButton: false,
      allowOutsideClick: false,
      padding: '1em',
      customClass: {
        popup: `loading-alert loading-alert--${color || 'success'}`
      }
    })
  }
  Vue.prototype.$newSuccess = (title, text, size) => {
    Swal.fire({
      position: 'bottom-start',
      icon: 'success',
      title: title || 'Completado',
      text: text || '',
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: true,
      timerProgressBar: true,
      timer: 5000,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer
        toast.onmouseleave = Swal.resumeTimer
        const element = Swal.getPopup()
        element.classList.remove('swal2-modal')
        element.classList.add('swal2-toast')
        document.querySelector('.swal2-success-fix').style = ''
        document.querySelector('.swal2-success-circular-line-right').style = ''
        document.querySelector('.swal2-success-circular-line-left').style = ''
        document.querySelector('.swal2-close').blur()
      },
      customClass: {
        popup: `newSuccess-alert-popup new-alert-popup-${size ? size : 'small'}`,
        icon: 'newSuccess-alert-icon',
        closeButton: 'new-alert-close',
        title: 'new-alert-title'
      }
    })
  }
  Vue.prototype.$newError = (title, text, size) => {
    Swal.fire({
      position: 'bottom-start',
      icon: 'error',
      title: title || 'Error',
      text: text || '',
      showCloseButton: true,
      allowOutsideClick: true,
      showConfirmButton: false,
      didOpen: () => {
        const element = Swal.getPopup()
        element.classList.remove('swal2-modal')
        element.classList.add('swal2-toast')
        document.querySelector('.swal2-close').blur()
      },
      customClass: {
        popup: `newError-alert-popup new-alert-popup-${size ? size : 'small'}`,
        icon: 'newError-alert-icon',
        closeButton: 'new-alert-close',
        title: 'new-alert-title'
      }
    })
  }
}
