<template>
  <div>
    <b-modal 
      :title="modalTitle"
      size="md"
      ref="modalCustomAlert" 
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      no-fade
      modal-class="modal-background--multi-color"
    >
      <div class="d-block text-center">
        <span :class="`text-${type}`" class="iconContainer mt-2"><feather-icon :icon="icon" size="7x"/></span>
        
        <div class="my-2">
          <h6>{{ title }}</h6>
          <div class="mb-1" v-if="error">
            <code class="text-secondary error-description">Error: {{ error }}</code>
          </div>
          <small class="info-description" v-html="info"></small>
        </div>
        
        
        <div class="pb-5" v-if="multipleOption">
          <b-button class="px-3" size="sm" :disabled="loading" :variant="`outline-warning`"  @click="hideModal">{{ closeBtnMult }}</b-button>
          <b-button class="px-3 ml-2" :disabled="loading" size="sm" :variant="`outline-success`"  @click="recall">
            <b-spinner v-if="loading" small></b-spinner>
            <span v-else>{{ actionBtnMult }}</span></b-button>
        </div>
        <div class="pb-5" v-else>
          <b-button class="px-4" size="sm" :variant="`outline-${btnColor}`"  @click="hideModal">{{ closeBtnText }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    modalTitle: {
      type: String,
      default: 'Completado'
    },
    title: String,
    info: String,
    action: {
      type: Function,
      default: null
    },
    closeBtnText: {
      type: String,
      default: 'Aceptar'
    },
    icon: {
      type: String,
      default: 'CheckCircleIcon'
    },
    type: {
      type: String,
      default: 'success'
    },
    btnColor: {
      type: String,
      default: 'warning'
    },
    multipleOption: {
      type: Boolean,
      default: false
    },
    closeBtnMult: {
      type: String,
      default: 'Salir'
    },
    error: {
      type: String,
      default: ''
    },
    recallAction: {
      type: Function,
      default: null
    },
    actionBtnMult: {
      type: String,
      default: 'Volver a intentarlo'
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    }
  },
  methods: {
    showModal() {
      this.$refs.modalCustomAlert.show()
    },
    hideModal() {
      this.$refs.modalCustomAlert.hide()
      if (this.action) {
        this.action()
      }
      this.$emit('close')
    },
    async recall() {
      this.loading = true
      try {
        await this.recallAction()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
        this.$refs.modalCustomAlert.hide()
      }
    }
  },
  mounted() {
    if (this.multipleOption && !this.info) {
      this.info = 'Le recomendamos presionar el botón <span class="text-primary font-weight-bold">“Volver a Intentarlo”</span>. <br>Si el inconveniente persiste, ponte en contacto con Support para recibir asistencia.'
    }
    this.showModal()
  }
}
</script>

<style scoped>
.iconContainer {
  width: 7em;
  height: 7em;
  margin: auto;
  display: flex;
}

.error-description {
  margin-bottom: 5px;
  font-size: 11px;
  background-color: #FFF;
  color: #5C5C5C;
}
</style>
