import Vue from 'vue'
import Swal from 'sweetalert2'

Vue.mixin({
  methods: {
    handlerStandartError(error, brTag = true) {
      const isStandardErr = Array.isArray(error?.errors) && error?.errors[0]?.detail
      const subtitle = isStandardErr ? `${error?.errors[0]?.title}${brTag ? '<br>' : ''}` : ''
      const detail = isStandardErr ? error.errors.map((e) => (Array.isArray(e.detail) ? e.detail.join('.<br>') : e.detail)).join('.<br>') : error.message
      return { subtitle, detail }
    },

    downloadLink(url, name = 'Descarga') {  
      const link = document.createElement('a')
      link.href = url
      link.download = name
    
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    m_getElementIndex(array, key, value) {
      return array.findIndex(element => element[key] === value)
    },

    m_cleanObjFrom(object, keyName) {
      const keys = Object.keys(object)
      const startIndex = keys.indexOf(keyName)

      if (startIndex !== -1) {
        const keysToRemove = keys.slice(startIndex)
        keysToRemove.forEach(key => {
          delete object[key]
        })
        return object
      } else {
        return {error: `No existe la key: ${keyName} en ${object}`}
      }
    },

    m_validateEmail (emails) {
      const filter = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      const validate = emails.map(item => filter.test(item)).includes(false)
      return validate
    },

    m_haveSpecialPermission(name) {
      return this.$session.get('cas_user').special_permissions.includes(name)
    },

    m_changeTextI18n(countryCode, key) {
      const texts = this.$i18nImport(`${countryCode?.toLowerCase()}.es`)[`${countryCode?.toLowerCase()}.es`]
      return this.$t(texts[key])
    },

    m_closeAlertLoading() {
      Swal.update({customClass: {}})
      Swal.close()
    },
    m_generateHOurs(start, end, interval) {
      const schedules = []
      let [hour, minute] = start.split(':').map(Number)
      const [endTime, minuteEnd] = end.split(':').map(Number)

      while (hour < endTime || (hour === endTime && minute <= minuteEnd)) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        schedules.push({ id: time, text: time })
        
        minute += interval
        if (minute >= 60) {
          minute -= 60
          hour += 1
        }
      }

      return schedules
    },
    m_setSkeletonField(array, key, name, bool) {
      if (this.m_getElementIndex(array, key, name) !== -1) {
        array[this.m_getElementIndex(array, key, name)].useSlot = bool
        array[this.m_getElementIndex(array, key, name)].useSkeleton = bool
        array[this.m_getElementIndex(array, key, name)].useLabel = bool
      }
    },
    m_findChangedFields(obj1, obj2) {
      const changedFields = {}
      for (const key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, key)) {
          if (obj1[key] !== obj2[key]) {
            changedFields[key] = obj1[key]
          }
        }
      }
      return changedFields
    },
    m_parsedValueObject(array) {
      const objectJSON = {}
      array.forEach(({ key, value }) => {
        let valor = value
        try {
          const parsedValue = JSON.parse(value)
          if (typeof parsedValue === 'object' && parsedValue !== null) {
            valor = parsedValue
          }
        } catch (e) {
          console.error(`${key} no es un JSON válido`)
        }
        objectJSON[key] = valor
      })

      return objectJSON
    }
  }
})
