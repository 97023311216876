import { environment } from '@/environments/environment'
export default [
  { name: 'getCouriers', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-organization-shippers-new`, method: 'get', notCancel: true },
  { name: 'getCarriers', url: `${environment.tokyoApiUrl}/v1/carriers/carriers`, method: 'get' },
  { name: 'deleteCarriers', url: `${environment.tokyoApiUrl}/v1/carriers/carriers/{carrier_id}`, method: 'delete', notCancel: true },
  { name: 'getCarrier', url: `${environment.tokyoApiUrl}/v1/carriers/carriers/{carrier_id}`, method: 'get' },
  { name: 'createCarriers', url: `${environment.tokyoApiUrl}/v1/carriers/carriers`, method: 'post' },
  { name: 'getServicesCarrier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-services`, method: 'get' },
  { name: 'getClasificationServices', url: `${environment.tokyoApiUrl}/v1/carriers/service-classifications/countries/{id_country}`, method: 'get' },
  { name: 'createServicesCarrier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-services`, method: 'post' },
  { name: 'getServiceCarrier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-services/{carrier_service_id}`, method: 'get' },
  { name: 'deleteServiceCarrier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-services/{carrier_service_id}`, method: 'delete', notCancel: true },
  { name: 'updateServicesCarrier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-services/{carrier_service_id}?force=true`, method: 'put' },
  { name: 'getServiceType', url: `${environment.tokyoApiUrl}/v1/carriers/service-type/SERV_TYPE`, method: 'get' },
  { name: 'getPickupType', url: `${environment.tokyoApiUrl}/v1/carriers/service-type/PICKUP_TYPE`, method: 'get' },
  { name: 'getSchedulingType', url: `${environment.tokyoApiUrl}/v1/carriers/service-type/SCHEDULING_TYPE`, method: 'get' },
  { name: 'getActions', url: `${environment.tokyoApiUrl}/v1/carriers/service-type/ACC_TYPE`, method: 'get' },
  { name: 'getCarrierStatus', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-status`, method: 'get' },
  { name: 'getCarrierConfigurations', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-configurations`, method: 'get' },
  { name: 'createCarrierConfigurations', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-configurations`, method: 'post' },
  { name: 'updateCarrierConfigurations', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-configurations/{configuration_id}`, method: 'put' },
  { name: 'getCarrierConfigurationsRequest', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-requests`, method: 'get' },
  { name: 'createCarrierConfigurationsRequest', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-requests`, method: 'post' },
  { name: 'updateCarrierConfigurationsRequest', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-requests/{request_id}`, method: 'put' },
  { name: 'deleteCarrierConfigurationsRequest', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-requests/{request_id}`, method: 'delete', notCancel: true },
  { name: 'getContractType', url: `${environment.tokyoApiUrl}/v1/carriers/service-type/CONT_TYPE`, method: 'get' },
  { name: 'getCarrierProducts', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-types`, method: 'get' },
  { name: 'deleteCarrierProducts', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-types/{carrier_type_id}`, method: 'delete', notCancel: true },
  { name: 'createCarrierProducts', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-types`, method: 'post' },
  { name: 'updateCarrierProducts', url: `${environment.tokyoApiUrl}/v1/carriers/carriers-types/{carrier_type_id}`, method: 'put' },
  { name: 'getDeliveyPrueba', url: `${environment.tokyoApiUrl}/v1/carriers/adi-deliveries/{delivery_id}`, method: 'get' },
  { name: 'addLogoCourier', url: `${environment.tokyoApiUrl}/v1/carriers/carriers/add-logotype/{code}`, method: 'put' },
  { name: 'createWarehouseShipper', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-shipper-warehouses/alternative`, method: 'post' },
  { name: 'updateWarehouseShipper', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-shipper-warehouses/alternative/{b2bId}`, method: 'put' },
  { name: 'deleteWarehouseShipper', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-shipper-warehouses/{warehouse_id}`, method: 'delete', notCancel: true },
  { name: 'getWarehouseShipper', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-shipper-warehouses`, method: 'get' },
  { name: 'addLogoCourier', url: `${environment.tokyoApiUrl}/v1/carriers/carriers/add-logotype/{code}`, method: 'put' },
  { name: 'forceTracking', url: 'https://platform2.enviame.io/manual-tracking?deliveries={delivery_id}', method: 'get' },
  { name: 'getCredentialsCourier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-account-params/by-args`, method: 'get' },
  { name: 'addCredentialsCourier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-account-params`, method: 'post' },
  { name: 'updateCredentialsCourier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-account-params/{id_credentials}`, method: 'put' },
  { name: 'deleteCredentialsCourier', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-account-params/{id_credentials}`, method: 'delete', notCancel: true },
  {
    name: 'downloadGeneralConditions', url: `${environment.tokyoApiUrl}/v1/carriers/excel-carrier-services`, method: 'get', notCancel: true, headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  },
  { name: 'uploadCarrierConditions', url: `${environment.tokyoApiUrl}/v1/carriers/upload-excel-specific-conditions`, method: 'post' },
  { name: 'getCarriersAccountLogs', url: `${environment.tokyoApiUrl}/v1/carriers/account-change-logs`, method: 'get' },
  { name: 'getCarrierAccountLogsById', url: `${environment.tokyoApiUrl}/v1/carriers/account-change-logs/{id}`, method: 'get' },
  { name: 'getInsuranceActivateType', url: `${environment.tokyoApiUrl}/v1/carriers/service-type/HOWACTIVATE_TYPE`, method: 'get' },
  { name: 'getCourtHourType', url: `${environment.tokyoApiUrl}/v1/carriers/service-type/COURTHOURS_TYPE`, method: 'get' },
  { name: 'getAllCarriers', url: `${environment.tokyoApiUrl}/v1/carriers/carriers/filter`, method: 'get', notCancel: true },
  { name: 'updateTransitDays', url: `${environment.minatoApiUrl}/transit-days-modifications-x-services/creation-form`, method: 'post', notCancel: true }
]
