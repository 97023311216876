import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'
import deliveries from './deliveries'
import organizations from './organizations'
import folders from './folders'
import global from './global'
import shippers from './shippers'
import advancedRules from './advancedRules'
import pricing from './pricing'
import pudos from './pudos'
import storeManagement from './storeManagement'
import integrations from './integrations'
import billing from './billing'
import carriers from './carriers'
import companies from './companies'
import returns from './returns'
import tickets from './tickets'
import marketcenter from './marketcenter'
import carriersNY from './carriersNY'
import defaultAnswer from './defaultAnswer'
import ratesQuoter from './ratesQuoter'
import reportProblem from './reportProblem'
import originsDestinations from './originsDestinations'
import configCarriers from './configCarriers'
import quoter from './quoter'
import userAssign from './userAssign'
import pricingWeb from './pricingWeb'
import enviaFlash from './enviaFlash'
import notifications from './notifications'
import protocolCompany from './protocolCompany'
import reportCarriers from './reportCarriers'
import pickups from './pickups'
import collectionOnDelivery from './collectionOnDelivery'
import webhooks from './webhooks'
import manifest from './manifest'
import additionalCharges from './additionalCharges'
import proveRules from './proveRules'
import team from './team'
import dashboard from './dashboard'
import margins from './margins'
import integrationMeli from './integrationMeli'

const services = [
  // Global

  ...organizations,
  ...deliveries,
  ...global,
  ...folders,
  ...shippers,
  ...advancedRules,
  ...pricing,
  ...integrations,
  ...billing,
  ...carriers,
  ...companies,
  ...returns,
  ...tickets,
  ...marketcenter,
  ...carriersNY,
  ...defaultAnswer,
  ...ratesQuoter,
  ...reportProblem,
  ...originsDestinations,
  ...pudos,
  ...storeManagement,
  ...quoter,
  ...configCarriers,
  ...userAssign,
  ...pricingWeb,
  ...enviaFlash,
  ...notifications,
  ...protocolCompany,
  ...reportCarriers,
  ...pickups,
  ...collectionOnDelivery,
  ...webhooks,
  ...manifest,
  ...additionalCharges,
  ...proveRules,
  ...team,
  ...dashboard,
  ...margins,
  ...integrationMeli,

  { name: 'getCarriers', url: `${environment.madagascarApiUrl}/carriers`, method: 'get' },
  { name: 'getCarriersByShipper', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/carriers`, method: 'get' },
  { name: 'getCarriersByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/carriers`, method: 'get' },
  { name: 'getTrackingCarriers', url: `${environment.madagascarApiUrl}/tracking-carriers`, method: 'get' },
  { name: 'getAddresses', url: `${environment.madagascarApiUrl}/shippers/{shipper_id}/addresses`, method: 'get' },
  { name: 'getManageBillingData', url: `${environment.miamiApiUrl}/v1/billing/companies`, method: 'get' },
  { name: 'openNewTicket', url: `${environment.madagascarApiUrl}/shippers/{shipper_id}/tickets`, method: 'post' },
  { name: 'getShippersByOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get' },
  { name: 'getShippersByOrganizationSimplified', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers-simplified`, method: 'get' },
  { name: 'getShippersByOrganizationSimplifiedQueryParams', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers?simplified=true`, method: 'get' },
  { name: 'getTrackingCarriers', url: `${environment.madagascarApiUrl}/tracking-carriers`, method: 'get' },
  { name: 'getAddresses', url: `${environment.madagascarApiUrl}/shippers/{shipper_id}/addresses`, method: 'get' },
  { name: 'openNewTicket', url: `${environment.madagascarApiUrl}/shippers/{shipper_id}/tickets`, method: 'post' },
  { name: 'getShippersByOrganization', url: `${environment.madagascarApiUrl}/organizations/{organization_id}/shippers`, method: 'get' },
  { name: 'getPlaceTemplate', url: `${environment.nippurApiUrl}/get_place_template`, method: 'get' },
  { name: 'getCountriesToronto', url: `${environment.torontoApiUrl}/countries`, method: 'get'}
]

export default class BaseServices {
  async callService(name, queryParams = {}, params = {}, config = {}, headers = {}, responseType) {
    const service = services.filter(service => service.name === name)[0]
    if (responseType) {
      service.responseType = responseType
    }
    return useJwt.genericService(service, queryParams, params, config, headers)
  }

  async callServiceArrayQueryParams(name, queryParams = [], params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }

  async callServiceStatusOnError(name, queryParams = {}, params = {}, config = {}, getStatusOnError = null) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params, config, null, getStatusOnError)
  }

  async callMultipleServices(callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
  async callSpecialUploadFile(name, file = null, params = {}, user = {}, queryParams = {}, nameFiles = 'file') {
    let formData = new FormData()
    formData = useJwt.genericDataUser(formData, user)
    if (!!file && !!Array.isArray(file)) {
      file.forEach(element => {
        formData.append(nameFiles, element)
      })
    }
    else if (!!file && !Array.isArray(file)) {
      formData.append(nameFiles, file)
    }

    for (const key in queryParams) {
      if (Object.hasOwnProperty.call(queryParams, key)) {
        formData.append(key, queryParams[key])
      }
    }
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericUploadFile(service, formData, params, true)
  }
  async callUploadFile(name, data, params = {}, user = {}) {
    let formData = new FormData()
    formData = useJwt.genericDataUser(formData, user)
    if (data.file) formData.append('file', data.file)
    if (data['file-cp-coverages']) formData.append('file-cp-coverages', data['file-cp-coverages'])
    if (data['file-cp-ranges']) formData.append('file-cp-ranges', data['file-cp-ranges'])
    if (data['file-cp-zones']) formData.append('file-cp-zones', data['file-cp-zones'])
    if (data['file-cp-transitDays']) formData.append('file-transit-days-group', data['file-cp-transitDays'])
    if (data.new_date) formData.append('new_date', data.new_date)
    if (data.user_id) formData.append('user_id', data.user_id)
    if (data.validity_date) formData.append('validity_date', data.validity_date)
    if (data.update_now) formData.append('update_now', data.update_now)
    if (data.freight_rate_id) formData.append('freight_rate_id', data.freight_rate_id)
    if (data.freight_type) formData.append('freight_type', data.freight_type)
    if (data.additional_info_file) formData.append('additional_info_file', data.additional_info_file)
    if (data['logotypes[]']) formData.append('logotypes[]', data['logotypes[]'])
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericUploadFile(service, formData, params, true)
  }

  async callNewReport(name, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    const formData = new FormData()
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        if (Array.isArray(params[key])) {
          for (let index = 0; index < params[key].length; index++) {
            const element = params[key][index]
            formData.append(key, element)
          }
        } else {
          formData.append(key, params[key])
        }
      }
    }
    return useJwt.genericService(service, formData)
  }

  goToPlatform(path = '', blanc = true) {
    const textBlanc = blanc ? '_blank' : '_self'
    window.open(`${environment.platformUrl}/${path}`, textBlanc)
  }
  async getPlaceTemplate(queryParams = {}, params = {}, config = {}) {
    const service = services.filter(service => service.name === 'getPlaceTemplate')[0]
    const response = await useJwt.genericService(service, queryParams, params, config)
    window.open(response.data, '_blank')
  }

  async callUploadFileCustomParams(name, data, params = {}, user = null, headers = null) {
    let formData = new FormData()
    if (user) formData = useJwt.genericDataUser(formData, user)
    //Recorre el objeto data y crea los valores en formData
    Object.keys(data).forEach(key => {
      const isJustObject = typeof data[key] === 'object' && data[key].constructor !== File
      key === 'files[]' ? data[key].forEach(file => formData.append('files[]', file)) : isJustObject ? formData.append(key, JSON.stringify(data[key])) : formData.append(key, data[key])
    })
    const service = services.filter(service => service.name === name)[0]
    if (headers) service.headers = { ...headers }
    return useJwt.genericUploadFile(service, formData, params, true)
  }
  async callUploadFileFormData(name, data, params = {}, user = null, headers = null) {
    let formData = new FormData()
    if (user) formData = useJwt.genericDataUser(formData, user)
    data.forEach(function(value, key) {
      formData.append(key, value)
    })
    const service = services.filter(service => service.name === name)[0]
    if (headers) service.headers = { ...headers }
    return useJwt.genericUploadFile(service, formData, params, true)
  }
}
