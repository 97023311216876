<template>
  <div>
    <b-form-group :label="optionalSecondLabel" >
      <b-form-radio-group v-if="!customRadio"
        v-model="myValue"
        :options="options"
        value-field="id"
        :disabled="disabled"
        @change="onChange"
        :stacked="align !== 'h'"
        :class="(typeof msg === 'undefined') ? '' : 'error-invalid-input'"
      >
      </b-form-radio-group>
      <b-form-radio-group v-if="!!customRadio"
        value-field="id"
        v-model="myValue"
        v-slot="{ ariaDescribedby }"
        :class="align === 'h' ? 'd-flex justify-content-start' : ''"
        @change="onChange"
      >
          <div v-for="(option, index) in options" :key="index" :class="option.class">
            <div class="container-img" v-if="option.img">
              <img :src="option.img" :alt="`imagen_${option.text}`" class="custom-radio custom-control" :style="option.imgStyles">
            </div>
            <b-form-radio :value="option.id" :disabled="option.disabled" :aria-describedby="ariaDescribedby">
              {{ option.text }}
            </b-form-radio>
            <p class="custom-radio custom-control mr-1"  v-if="option.content" :style="option.contentStyles">{{option.content}}</p>
          </div>
      </b-form-radio-group>
      <b-form-invalid-feedback :state="(typeof msg === 'undefined') ? undefined : false" v-if="msg && msg.text">
          {{msg.text}}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: ['options', 'value', 'align', 'msg', 'disabled', 'change', 'name', 'optionalSecondLabel', 'customRadio'],
  name: 'field-radio',
  data() {
    const defaultValue = this.options?.find(option => option.default)?.id || null
    return {
      myValue: defaultValue
    }
  },
  mounted () {
    this.setChecked()
  },
  watch: {
    value () {
      this.setChecked()
    }
  },
  methods: {
    setChecked () {
      this.myValue = this.value && this.value.id ? this.options.find(({id}) => this.value.id === id)?.id : this.myValue
    },
    onChange (val) {
      const valueUpdated = this.myValue ? this.options.filter(({id}) => this.myValue === id)[0] : {}
      this.$emit('update:value', valueUpdated)
      if (this.change) this.change(this.name, valueUpdated)
    }
  }
}
</script>
<style lang="scss" scoped>
.container-img {
  display: flex;
  align-items: center;
  height: 50px;
}
// div[fieldType="FieldRadio"] > fieldset {
//   max-height: 38px;
// }
// .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
//     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 7 7'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
// }
</style>

<style lang="scss">
.error-invalid-input{
    .custom-radio .custom-control-label::before, .custom-radio .custom-control-label::after {
      border-color: #dc3545;
    }
  
}
</style>
