import { environment } from '@/environments/environment'

export default [
  // LISTADO DE COMPAÑÍAS
  // DOC: https://enviame-latam.atlassian.net/wiki/spaces/RT/pages/1370423326/Listado+de+empresa+-+endpoints
  { name: 'getCompaniesListData', url: `${environment.apiPlatform}/v1/companies`, method: 'get' },
  { name: 'getPlacesCompanies', url: `${environment.apiPlatform}/v1/places/{country_id}`, method: 'get' },
  { name: 'getZipcodeCompanies', url: `${environment.apiPlatform}/v1/zip-codes/{country_id}`, method: 'get' },
  { name: 'getMarketplacesCompanies', url: `${environment.apiPlatform}/v1/marketplaces`, method: 'get' },
  { name: 'getExecutivesCompanies', url: `${environment.apiPlatform}/v1/executives`, method: 'get' },
  { name: 'deleteCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}`, method: 'delete' },
  { name: 'createNewCompany', url: `${environment.apiPlatform}/v1/companies`, method: 'post' },
  { name: 'createNewCompanyBillingData', url: `${environment.apiPlatform}/v1/companies/{company_id}/business-data`, method: 'post' },

  // Nuevo rediseño
  { name: 'putEditCompanyNew', url: `${environment.apiPlatform}/v1/new/companies/{company_id}`, method: 'put' },
  // Crud de contactos tomadores de decisiones
  { name: 'getAuthorityContacts', url: `${environment.apiPlatform}/v1/companies/{company_id}/authority-contacts`, method: 'get' },
  { name: 'postAuthorityContact', url: `${environment.apiPlatform}/v1/companies/{company_id}/authority-contacts`, method: 'post' },
  { name: 'putAuthorityContact', url: `${environment.apiPlatform}/v1/companies/{company_id}/authority-contacts`, method: 'put' },
  { name: 'deleteAuthorityContact', url: `${environment.apiPlatform}/v1/companies/{company_id}/authority-contacts`, method: 'delete' },

  // Crud de ejecutivos enviame
  { name: 'getExecutivesCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/executive`, method: 'get' },
  { name: 'postExecutiveCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/executive`, method: 'post' },
  { name: 'deleteExecutiveCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/executive`, method: 'delete' },

  // Activación-desactivación
  { name: 'changeStatusCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/status`, method: 'put'},
  
  // CONFIGURACIÓN DE COMPAÑÍAS con Courier
  { name: 'getCourierCompanies', url: `${environment.apiPlatform}/v1/carriers-list`, method: 'get' },
  { name: 'getCourierConfiguration', url: `${environment.apiPlatform}/v1/carriers-specials/companies/{company_id}`, method: 'get' },
  { name: 'updateCourierConfiguration', url: `${environment.apiPlatform}/v1/companies/bloked-service/{company_id}/carrier/{carrier_id}`, method: 'put' },
  { name: 'createCourierConfiguration', url: `${environment.apiPlatform}/v1/companies/{company_id}/configurations`, method: 'post', notCancel: true },
  { name: 'deleteCourierConfiguration', url: `${environment.apiPlatform}/v1/companies/{company_id}/carrier-company/{carrier_company_id}`, method: 'delete' },
  { name: 'updateMailTemplate', url: `${environment.apiPlatform}/v1/companies/{company_id}/return-company-mail`, method: 'put' },


  // Bodegas
  { name: 'getWarehouseCompanies', url: `${environment.apiPlatform}/v1/company/{company_id}/warehouses`, method: 'get' },
  { name: 'createWarehouseCompanies', url: `${environment.apiPlatform}/v1/company/{company_id}/warehouses`, method: 'post' },
  { name: 'updateWarehouseCompanies', url: `${environment.apiPlatform}/v1/company/{company_id}/warehouses/{warehouse_id}`, method: 'put' },
  { name: 'deleteWarehouseCompanies', url: `${environment.apiPlatform}/v1/company/{company_id}/warehouses/{warehouse_id}`, method: 'delete' },
  { name: 'createNewCompanyBillingData', url: `${environment.apiPlatform}/v1/companies/{company_id}/business-data`, method: 'post' },
  { name: 'getFullPlaces', url: `${environment.apiPlatform}/v1/temporary-places/{country_id}`, method: 'get' },
  { name: 'warehouseCacheClear', url: `${environment.puconApiUrl}/shippers/{shipper_id}/warehouse/{warehouse_code}/warehouse-cache-clear`, method: 'post' },
  // DETALLE DE COMPAÑIAS
  { name: 'getExecutivesCompanies', url: `${environment.apiPlatform}/v1/executives`, method: 'get' },
  { name: 'getCompanyInformation', url: `${environment.apiPlatform}/v1/companies/{company_id}`, method: 'get' },
  { name: 'configurationShipper', url: `${environment.torontoApiUrl}/shippers/{shipper_code}/config `, method: 'get' },
  { name: 'validateAddress', url: 'https://maps.googleapis.com/maps/api/geocode/json', method: 'get' },
  { name: 'getAllSellersMarketplace', url: `${environment.apiPlatform}/v1/marketplaces/{company_id}/sellers`, method: 'get' },
  { name: 'getAllMarketplacesCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/marketplaces`, method: 'get' },
  { name: 'addNewMarkeplaceCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/marketplaces/{marketplace_id}`, method: 'post' },
  { name: 'configAPiKeyMarkeplaceCompany', url: `${environment.apiPlatform}/v1/company-marketplaces/{company_marketplace_id}`, method: 'put' },
  { name: 'deleteMarkeplaceCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/marketplaces/{marketplace_id}`, method: 'delete' },
  { name: 'getCompaniesExtraFields', url: `${environment.apiPlatform}/v1/companies/{company_id}/extra-fields`, method: 'get' },
  { name: 'updateCompaniesExtraFields', url: `${environment.apiPlatform}/v1/companies/{company_id}/extra-fields`, method: 'post' },
  { name: 'getListIntegrationsCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/integrations `, method: 'get'},
  { name: 'changeIntegrationStatusCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/manager-integrations `, method: 'post'},
  { name: 'updateIntegrationsCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}/integrations `, method: 'post'},
  { name: 'getStatusShipped', url: `${environment.apiPlatform}/v1/deliveries/home-statuses `, method: 'get'},
  { name: 'updateCompaniesNotifications', url: `${environment.apiPlatform}/v1/companies/{company_id}/notifications`, method: 'post' },
  { name: 'updateCompaniesLogo', url: `${environment.apiPlatform}/v1/company-logo/{company_id}`, method: 'post' },
  { name: 'testNotification', url: `${environment.apiPlatform}/v1/companies/{company_id}/test-notification`, method: 'post' },
  { name: 'updateCustomization', url: `${environment.apiPlatform}/v1/companies/{company_id}/customization `, method: 'put' },
  { name: 'updateConfiguration', url: `${environment.torontoApiUrl}/shippers/{shipper_code}/config`, method: 'post' },
  { name: 'sendEmailTestDelivery', url: `${environment.torontoApiUrl}/send-customer-email?notification_type=post_delivery`, method: 'post' },
  { name: 'sendEmailTestAddress', url: `${environment.torontoApiUrl}/send-customer-email?notification_type=right_address`, method: 'post' },
  {name: 'getCompanyRules', url: `${environment.nippurApiUrl}/company/{company_id}/rule`, method: 'get' },
  {name: 'getCarrierListByCompany', url: `${environment.apiPlatform}/v1/carriers-list/companies/{company_id}`, method: 'get' },

  // Obtener un Seller
  { name: 'getSeller', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/organizations/search`, method: 'get' },

  // Employees
  { name: 'getEmployees', url: `${environment.apiPlatform}/v1/companies/{company_id}/employees`, method: 'get' },
  { name: 'createEmployees', url: `${environment.apiPlatform}/v1/companies/{company_id}/employees`, method: 'post' },
  { name: 'deleteEmployees', url: `${environment.apiPlatform}/v1/employees/{employee_id}`, method: 'delete' },
  { name: 'updateEmployees', url: `${environment.apiPlatform}/v1/employees/{employee_id}`, method: 'put' },
  { name: 'postSellerMassive', url: `${environment.apiPlatform}/v1/company/warehouse/upload`, method: 'post'},
  { name: 'getApiKey', url: `${environment.apiPlatform}/v1/company-token/{company_id}`, method: 'put' },
  { name: 'putEditCompany', url: `${environment.apiPlatform}/v1/companies/{company_id}`, method: 'put' },
  { name: 'changeLogo', url: `${environment.apiPlatform}/v1/company-logo/{company_id}`, method: 'post' },
  { name: 'getRoles', url: `${environment.apiPlatform}/v1/roles/companies/{company_id}`, method: 'get' }

]
