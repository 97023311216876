var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{key:_vm.keySelect,class:[
    !!_vm.mainColor? ("v-select--" + _vm.mainColor) : '',
    (typeof _vm.msg === 'undefined')? '' : 'is-invalid',
    _vm.multiple? 'select-multiple' : '' ],attrs:{"id":_vm.id,"calculate-position":_vm.setPositionList,"placeholder":_vm.placeholder || 'Seleccione una opción',"dir":'ltr',"label":"text","multiple":!!_vm.multiple,"disabled":_vm.disabled,"reduce":_vm.reduce,"options":_vm.myOptions,"selectable":function (option) { return !option.disabled; },"filter":_vm.filter,"close-on-select":!_vm.notCloseOnSelect,"map-keydown":function (map, vm) { return _vm.handlers(map, vm); },"append-to-body":"","clearable":_vm.clearable,"persistent-hint":"true"},on:{"input":_vm.onChange,"search":_vm.onSearch,"search:focus":_vm.onSearchFocus,"search:blur":_vm.onSearchBlur},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
  var search = ref.search;
  var searching = ref.searching;
return [(_vm.validateShowText(search, searching, 'no-match-normal') && !_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Sin coincidencias para'))+" "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]):_vm._e(),(_vm.validateShowText(search, searching, 'no-options-normal') && !_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('No hay opciones cargadas.'))+" ")]):_vm._e(),(_vm.validateShowText(search, searching, 'search-match'))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Buscando coincidencias para'))+" "),_c('em',[_vm._v(_vm._s(search))]),_c('br'),_c('feather-icon',{staticClass:"spinner m-1",attrs:{"icon":"LoaderIcon","size":"2x"}})],1):_vm._e(),(_vm.validateShowText(search, searching, 'invalid-length') && !_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('msg-nchars', {key: _vm.searchOnType.nChars || 3}))+" ")]):_vm._e()]}},{key:"option",fn:function(ref){
  var id = ref.id;
  var text = ref.text;
  var name = ref.name;
  var icon = ref.icon;
  var color = ref.color;
  var header = ref.header;
  var code = ref.code;
  var colorIcon = ref.colorIcon;
return [(_vm.showIcon)?_c('feather-icon',{class:["option-select-icon", ("text-" + (!_vm.isSelected(id) ? color || '' : 'white'))],style:({'width': '17px', 'color': ("" + (colorIcon ? colorIcon : '')), fill: ("" + (colorIcon ? colorIcon : 'red'))}),attrs:{"icon":icon || 'square'}}):_vm._e(),(_vm.addFlags)?_c('img',{staticClass:"fieldSelect--img",class:[_vm.flagSize == 0 ? 'reduce-flag' : '', _vm.onlyFlag ? 'only-flag' : ''],attrs:{"src":require(("@/assets/images/flags/" + (code.toLowerCase()) + ".png")),"alt":("Bandera de " + name)}}):_vm._e(),(!_vm.mainColor && !_vm.onlyFlag)?_c('span',{class:[header ? 'header-group' : '', ("text-" + (!_vm.isSelected(id) ? color || '' : 'white'))]},[_vm._v(" "+_vm._s(_vm.specialName ? name : text || name)+" "+_vm._s(header))]):(_vm.mainColor && !_vm.onlyFlag)?_c('span',{class:[header ? 'header-group' : '']},[_vm._v(" "+_vm._s(_vm.specialName ? name : text || name)+" "+_vm._s(header))]):_vm._e()]}},(_vm.addFlags || _vm.elementIcon)?{key:"selected-option",fn:function(ref){
  var text = ref.text;
  var name = ref.name;
  var code = ref.code;
  var icon = ref.icon;
  var colorIcon = ref.colorIcon;
return [(_vm.addFlags)?_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('img',{staticClass:"fieldSelect--img",class:[_vm.flagSize == 0 ? 'reduce-flag' : ''],attrs:{"src":require(("@/assets/images/flags/" + (code.toLowerCase()) + ".png")),"alt":("Bandera de " + name)}}),_vm._v(" "+_vm._s(_vm.onlyFlag ? '' : text || name)+" ")]):_vm._e(),(_vm.elementIcon)?_c('div',[(_vm.showIcon)?_c('feather-icon',{class:["option-select-icon", ("text-" + (!_vm.isSelected(_vm.id) ? _vm.color || '' : 'white'))],style:({'width': '17px', 'color': ("" + (colorIcon ? colorIcon : '')), fill: ("" + (colorIcon ? colorIcon : 'red'))}),attrs:{"icon":icon || 'square'}}):_vm._e(),_vm._v(" "+_vm._s(text || name)+" ")],1):_vm._e()]}}:null,{key:"list-footer",fn:function(){return [(_vm.footer)?_c('div',{class:[_vm.footer.class ? _vm.footer.class : 'reload-footer', 'vSelect-footer border-top'],on:{"click":_vm.footerClick}},[(_vm.loadingFooter)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.footer.noIcon && !_vm.loadingFooter)?_c('feather-icon',{attrs:{"icon":_vm.footer.icon ? _vm.footer.icon : 'RefreshCcwIcon'}}):_vm._e(),_vm._v("  "+_vm._s(_vm.footer.text)+" ")],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.myValue),callback:function ($$v) {_vm.myValue=$$v},expression:"myValue"}}),(!!_vm.info)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.info)}}):_vm._e(),(_vm.msg && _vm.msg.text)?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.msg.text)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }