export default {
  state: {
    companiesListData: [],
    companyInformation: [],
    filteredCompaniesListData: [],
    warehouseCompanies: [],
    courierConfiguration: [],
    allSellersMarketplace: [],
    allMarketplacesCompany: [],
    companiesExtraFields: [],
    listIntegrationsCompany: [],
    statusShipped: [],
    courierCompanies: [],
    employees: [],
    apiKey: [],
    editCompany: [],
    editCompanyNew: [],
    changeLogo: [],
    roles: [],
    carrierListByCompany: [],
    authorityContacts: [],
    executivesCompany: []
  },
  getters: {
    getCompanyInformation: (state) => state.companyInformation,
    getCompaniesListData: (state) => state.companiesListData,
    getWarehouseCompanies: (state) => state.warehouseCompanies,
    getCourierConfiguration: (state) => state.courierConfiguration,
    getAllSellersMarketplace: (state) => state.allSellersMarketplace,
    getAllMarketplacesCompany: (state) => state.allMarketplacesCompany,
    getCompaniesExtraFields: state => state.companiesExtraFields,
    getCourierCompanies: (state) => state.courierCompanies,
    getEmployees: (state) => state.employees,
    getListIntegrationsCompany: state => state.listIntegrationsCompany,
    getStatusShipped: state => state.statusShipped,
    getApiKey: (state) => state.apiKey,
    putEditCompany: (state) => state.editCompany,
    putEditCompanyNew: (state) => state.editCompanyNew,
    changeLogo: (state) => state.changeLogo,
    getRoles: state => state.roles,
    getCarrierListByCompany: state => state.carrierListByCompany,
    // Nuevo rediseño
    getAuthorityContacts: (state) => state.authorityContacts,
    getExecutivesCompany: (state) => state.executivesCompany
  },
  mutations: {
    setCompaniesListData(state, val) {
      state.companiesListData = {
        rows: val.companies,
        pagination: {
          total_items: val.length
        }
      }
    },
    setCompanyInformation(state, val) {
      if (val.countries) val.countries.map((el) => ({ ...el, text: el.name }))
      if (val.executives) {
        val.executives = val.executives.map((el) => ({
          ...el,
          text: `${el.first_name ? el.first_name : ''} ${el.last_name ? el.last_name : ''}`
        }))
      }
      if (val.collection_executives) {
        val.collection_executives = val.collection_executives.map((el) => ({
          ...el,
          text: `${el.first_name ? el.first_name : ''} ${el.last_name ? el.last_name : ''}`
        }))
      }
      if (val.operational_escorts) {
        val.operational_escorts = val.operational_escorts.map((el) => ({
          ...el,
          text: `${el.first_name ? el.first_name : ''} ${el.last_name ? el.last_name : ''}`
        }))
      }
      if (val.rules) val.rules = val.rules.map((el) => ({ ...el, text: el.name }))
      if (val.pudo_groups) val.pudo_groups = val.pudo_groups.map((el) => ({ ...el, text: el.name }))
      if (val.inactive_reasons) val.inactive_reasons = val.inactive_reasons.map((el) => ({ ...el, text: el.name }))
      if (val.active_reasons) val.active_reasons = val.active_reasons.map((el) => ({ ...el, text: el.name }))
      state.companyInformation = {
        rows: val,
        data: {
          ...val,
          company: {
            ...val.company,
            type: !!val.company.marketplace ? {id: 3, text: 'Marketplace'} : !val.company.marketplace && (val.company.dubai_organization_id !== val.company.dubai_shipper_id) ? {id: 2, text: 'Seller'} : {id: 1, text: 'Empresa'}
          }
        }
      }
    },
    setWarehouseCompanies(state, val) {
      state.warehouseCompanies = {
        rows: val.warehouses,
        pagination: val.pagination,
        country: val.country
      }
    },
    setCourierConfiguration(state, val) {
      state.courierConfiguration = val.rows
    },
    setAllSellersMarketplace(state, val) {
      state.allSellersMarketplace = val.companies.map(MKPData => {
        return {
          ...MKPData,
          fullName: `${MKPData.name1} ${MKPData.name2 ? ` - ${MKPData.name2}` : ''}`
        }
      })
    },
    setAllMarketplacesCompany(state, val) {
      state.allMarketplacesCompany = {
        company_MKP: val.data[0].company_marketplaces.map(mkpData => {
          return {
            ...mkpData,
            statusClass: mkpData.status === 'active' ? 'success' : 'secondary'
          }
        }),
        available_MKP: val.data[0].available_marketplaces
      }
    },
    setCompaniesExtraFields(state, val) {
      state.companiesExtraFields = val.extra_fields
    },
    setListIntegrationsCompany(state, val) {
      state.listIntegrationsCompany = val
    },
    setStatusShipped(state, val) {
      state.statusShipped = val.home_statuses.map(statusData => ({
        ...statusData,
        text: statusData.name
      }))
    },
    setCourierCompanies(state, val) {
      state.courierCompanies = val.carriers
    },
    setEmployees(state, val) {
      state.employees = {
        rows: val.employees,
        data: val
      }
    },
    setApiKey(state, val) {
      state.apiKey = {
        data: val.token
      }
    },
    setEditCompany(state, val) {
      state.editCompany = {
        data: val
      }
    },
    setEditCompanyNew(state, val) {
      state.editCompanyNew = {
        data: val
      }
    },
    setChangeLogo(state, val) {
      state.changeLogo = {
        data: val
      }
    },
    setRoles(state, val) {
      state.roles = val.data.map(el => ({ ...el, text: el.name }))
    },
    setCarrierListByCompany(state, val) {
      state.carrierListByCompany = val.carriers.map(el => ({ ...el, text: el.name }))
    },
    setAuthorityContacts(state, val) {
      state.authorityContacts = val.data.map(el => ({ ...el, text: el.name }))
    },
    setExecutivesCompany(state, val) {
      state.executivesCompany = val.data.map(el => ({ ...el, text: el.name }))
    }
    /* Como deberia quedar cuando se cambie el modulo de edición de empresa
    setCompanyInformation(state, val) {
      if (val.countries) val.countries.map((el) => ({ ...el, text: el.name }))
      if (val.inactive_reasons) val.inactive_reasons = val.inactive_reasons.map((el) => ({ ...el, text: el.name }))
      if (val.active_reasons) val.active_reasons = val.active_reasons.map((el) => ({ ...el, text: el.name }))
      state.companyInformation = {
        rows: val,
        data: {
          ...val,
          company: {
            ...val.company,
            type: !!val.company.marketplace ? {id: 3, text: 'Marketplace'} : !val.company.marketplace && (val.company.dubai_organization_id !== val.company.dubai_shipper_id) ? {id: 2, text: 'Seller'} : {id: 1, text: 'Empresa'}
          }
        }
      }
    } */
  },
  actions: {}
}
