<template>
<div>
    <div class="d-flex justify-content-end mb-1 filter-columns-dropdown" v-if="columnFilter">
      <b-dropdown id="dropdown-filter-input" :variant="'link'" ref="dropdown"  menu-class="w-100 filterInput-menu-class">
        <template #button-content><feather-icon icon="ColumnsIcon" size="14" /> Columnas</template>
        <b-overlay
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
          class="filterInput-overlay">
          <b-form-checkbox-group
            v-model="selectedColumns"
            :options="columnOptions"
            value-field="key"
            text-field="label"
            :stacked="true"
            @change="updateVisibleColumns"
            class="column-checkbox-group"
          ></b-form-checkbox-group>
          <b-dropdown-divider class="filterInput-dropdown-divider"></b-dropdown-divider>
          <b-form-checkbox
            v-model="selectAllColumns"
            @change="toggleAllColumns"
            >
            <span class="text-center w-full push-left">{{ 'Seleccionar todo' }}</span>
            </b-form-checkbox>
        </b-overlay>
      </b-dropdown>
  </div>
  <div class="table-render-skeleton" >
    <div :id="id">
      <b-table responsive="sm" :key="innerKey" :items="myRows"
      @sort-changed="sortChanged"
      :fields="tableFields"
      :busy="loading"
      :sort-by.sync="mySortBy"
      :sort-desc.sync="mySortDesc"
      :sort-direction="mySortDirection"
      :selectable="markRow"
      :small="small"
      :thead-tr-class="theadTrClass"
      :tbody-tr-class="tbodyTrClass"
      :borderless="borderless"
      :striped="striped"
      @row-selected="onRowSelected"
      :sticky-header="stickyHeader"
      :ref="`ref${id}`"
      :fixed="fixed"
      :class="[tableClass, { 'table-markCol': markCol }]"
      show-empty
      :empty-html="emptyHtml"
      >

        <template #empty="" v-if="!emptyHtml">
          <span class="text-center w-full push-left">{{ 'No se encontraron registros para mostrar' }}</span>
        </template>
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ ...field.style }"
          >
        </template>
        <template #cell()="data" @click="markRow === data.item.id ? setRowMarked(data.item.id) : null"
          :class="[...(field.class || [])]" >
          <span v-if="data.field.useSlot" :id="`${data.field.key}_${data.item.id}`" :currentValue="data.value" :canGroupBy="data.item.group_key"><slot :name="data.field.key" :rowdata="data.item">
          </slot></span>
          <!-- Evitar en lo posible el uso de useDangerHTML -->
          <span :id="`${data.field.key}_${data.item.id}`" :currentValue="data.value" :canGroupBy="data.item.group_key" v-else-if="(data.value || data.value === 0) && data.field.useDangerHTML" v-html="data.value"></span>

          <span :id="`${data.field.key}_${data.item.id}`" :currentValue="data.value" :canGroupBy="data.item.group_key" v-else-if="(data.value || data.value === 0) && !data.field.useDangerHTML"> {{ data.value }}</span>

          <span :id="`${data.field.key}_${data.item.id}`" :currentValue="data.value" :canGroupBy="data.item.group_key" v-else-if="!noFillVoid" :class="data.field.doubleDash ? 'no-info-center' : 'no-info'">{{data.field.doubleDash ? $t(' -- ') : $t(' - ')}}</span>
        </template>
        <template v-if="showCheckboxes" #cell(showCheckbox)="data"
          :class="field.class">
          <b-form-checkbox
            :id="`${data.item.id}`"
            :name="`${data.item.id}`"
            :value="data.item.id"
            v-model="mySelectedRows"
            :disabled="data.item.disabled_checkbox"
          >
          </b-form-checkbox>
        </template>
        <template #head()="data">
          <span v-if="data.field.customSort" :class="`header-icon-sort cursor-pointer ${(!sortCustomEle.active || sortCustomEle.active !== data.field.customSort) && 'header-icon-sort--disabled' }`" @click="() => callSortByCustom(data.field.customSort, data.field.customSort)">
            {{ data.field.label }}
            <img :src="require(`@/assets/images/icons/order-${
              (sortCustomEle.active === data.field.customSort && sortCustomEle.icon === 'down')
              ? 'down'
              : 'up'}.svg`)"
              alt="Ordenar por"/>
          </span>
          <span v-else @click="markCol ? setColMarked(data.field.key) : null" :class="{'cursor-pointer' : markCol }">
            <span v-if="!data.field.onlyIcon">{{ !data.field.noTranslate ? $t(data.field.label) : data.field.label }}</span>
            <feather-icon v-if="data.field.icon && !data.field.onlyIcon" :icon="data.field.icon" v-b-tooltip.top :title="!data.field.noTranslate ? $t(data.field.text) : data.field.text"/>
            <feather-icon v-if="data.field.icon && data.field.onlyIcon" :icon="data.field.icon" v-b-tooltip.top :title="!data.field.noTranslate ? $t(data.field.label || data.field.text) : (data.field.label || data.field.text) "/>
          </span>
        </template>
        <template v-if="showCheckboxes" #head(showCheckbox)=""
          :class="field.class">

          <b-form-checkbox
            v-model="selectedAll"
            :indeterminate="indeterminate"
            @change="() => selectAll(selectedAll)"
          >
          </b-form-checkbox>
        </template>
        <template v-if="useToolTip" #head(status)="data">
            <span>{{data.field.label}} <feather-icon :icon="data.field.toolTipIcon" id="icon-tooltip"></feather-icon></span>
            <b-popover target="icon-tooltip" placement="bottom" triggers="hover" custom-class="customize-tooltip">
              <template v-if="data.field.customizeTooltip.title.text !== ''" class="tooltip-text customize-tooltip" #title>{{data.field.customizeTooltip.title.text}}</template>
              <ul class="ul-tooltip">
                <li v-for="line in data.field.customizeTooltip.items" :key="line.id" class="li-tooltip">
                  <span :style="{'background-color': line.style.color, 'opacity': line.style.opacity }" class="item-tooltip"></span>
                  <p class="tooltip-text" v-html="line.text"></p>
                </li>
              </ul>
            </b-popover>
        </template>
        <template #thead-top="" v-if="topSchema">
          <b-tr v-for="(subSchema, index1) in topSchema" :key="`topSchema_${index1}`">
            <b-th v-for="(cell, index2) in subSchema" :key="`topSchema_${index1}_${index2}`" :colspan="cell.colspan || 1" :variant="cell.variant || ''">
              <span :class="cell.class">{{$t(cell.label)}}</span>
            </b-th>
          </b-tr>
        </template>
        <template #cell(actions)="data">
          <table-render-actions :actions="actions" :data="data"></table-render-actions>
        </template>
      </b-table>
    </div>
  </div>
</div>
</template>
<script>
import { BTable, BTr, BTh } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
/**
 * Un hermoso renderizador de tablas
 */
export default {
  name: 'table-render',
  components: {
    BTable, BTr, BTh
  },
  //nota: si usamos firebase procesaremos cada fila con .data()
  props: ['rows', 'schema', 'topSchema', 'id', 'showCheckboxes',
    'actions', 'loading', 'selectedRows', 'noFillVoid', 'sortBy', 'sortDesc', 'sortDirection', 'sortByCustom', 'markRow', 'markCol', 'tableClass', 'striped', 'stickyHeader', 'useToolTip',
    'groupBy', 'fixed', 'emptyHtml', 'small', 'theadTrClass', 'tbodyTrClass', 'borderless', 'defaultColumns', 'columnFilter'],
  data () {
    return {
      indeterminate: false,
      selectedAll: false,
      mySelectedRows: undefined,
      mySortBy: undefined,
      mySortDesc: undefined,
      mySortDirection: undefined,
      innerSelectedRows: [],
      mySchema: [],
      myRows: [],
      innerKey: 0,
      sortCustomEle: {
        icon: 'up',
        active: null,
        type: null
      },
      selectedColumns: [],
      selectAllColumns: false,
      columnOptions: []
    }
  },
  computed: {
    ...mapGetters({
      modularPermissions: 'getModularPermissions'
    }),
    tableFields() {
      if (!this.columnFilter) {
        return this.showCheckboxes ? [{label: '', key: 'showCheckbox', sortable: false, class: 'w-checkbox-table'}].concat(this.mySchema) : this.mySchema
      }
      const visibleFields = this.mySchema.filter(field => this.selectedColumns.includes(field.key))
      return this.showCheckboxes ? [{label: '', key: 'showCheckbox', sortable: false, class: 'w-checkbox-table'}].concat(visibleFields) : visibleFields
    }
  },
  watch: {
    modularPermissions () {
      this.mySchema = this.$filterUsingPermission(this.schema, this.modularPermissions)
    },
    loading () {
      if (this.loading) {
        // this.$vs.loading({container: `#${this.id}`})
      } else {
        // this.$vs.loading.close(`#${this.id} > .con-vs-loading`)
      }
    },
    columnFilter: {
      immediate: true,
      handler(newValue) {
        this.handleColumnFilter(newValue)
      }
    },
    schema() {
      this.mySchema = this.$filterUsingPermission(this.schema, this.modularPermissions)
      this.initializeColumns()
    },
    sortBy () {
      this.mySortBy = this.sortBy
    },
    sortDesc () {
      this.mySortDesc = this.sortDesc
    },
    sortDirection () {
      this.mySortDirection = this.sortDirection
    },
    mySortBy () {
      this.$emit('update:sortBy', this.mySortBy)
      this.generateMyRows('mySortBy')
    },
    mySortDesc () {
      this.$emit('update:sortDesc', this.mySortDesc)
      this.generateMyRows('mySortDesc')
    },
    mySortDirection () {
      this.$emit('update:sortDirection', this.mySortDirection)
      this.generateMyRows('mySortDirection')
    },
    selectedRows () {
      this.mySelectedRows = this.selectedRows
    },
    mySelectedRows () {
      const rows = this.rows.filter(el => !el.disabled_checkbox)
      this.indeterminate = !this.mySelectedRows || !this.rows || (this.mySelectedRows.length !== rows.length && this.mySelectedRows.length > 0)
      this.selectedAll = !this.indeterminate && this.mySelectedRows && this.rows && this.mySelectedRows.length === rows.length && rows.length > 0
      this.$emit('update:selectedRows', this.mySelectedRows)
    },
    rows () {
      this.myRows = [...(this.rows || [])]
    },
    myRows () {
      this.generateMyRows('watch')
    },
    defaultColumns() {
      this.initializeColumns()
    }
  },
  mounted () {
    this.mySortBy = this.sortBy
    this.mySortDesc = this.sortDesc
    this.mySortDirection = this.sortDirection
    this.myRows = [...(this.rows || [])]
    this.mySelectedRows = this.selectedRows
    this.mySchema = this.$filterUsingPermission(this.schema || [], this.modularPermissions)
    this.generateMyRows('mounted')
  },
  created() {
    this.initializeColumns()
  },
  methods: {
    handleColumnFilter(newValue) {
      if (newValue) {
        this.initializeColumns()
      } else {
        this.selectedColumns = this.mySchema.map(col => col.key)
      }
    },
    sortChanged (a, b) {
    },
    generateMyRows (origin) {
      if (this.groupBy) {
        const myRows = []
        // let prev = null
        // let rowspan = 1
        const currentRows = [...this.rows]

        if (document.querySelector('.special-hidden')) {
          document.querySelectorAll('.special-hidden').forEach(element => {
            element.classList.remove('special-hidden')
            element.classList.remove('hidden')
          })
          document.querySelectorAll('.has-rowspan').forEach(element => {
            element.classList.remove('has-rowspan')
            element.setAttribute('rowspan', '1')
          })
        }
        let prevValue = ''
        let prevId = ''
        let prevGroup = ''
        let rowspan = 1
        setTimeout(() => {
          document.querySelectorAll('table > tbody > tr > td > span').forEach(el => {
            if (el.id.split('_')[0] === this.groupBy) {
              if (el.attributes.currentValue?.nodeValue === prevValue && el.attributes.canGroupBy?.nodeValue === prevGroup && prevGroup !== '') {
                rowspan++
                document.querySelector(`#${prevId}`).closest('td').setAttribute('rowspan', rowspan)
                document.querySelector(`#${prevId}`).closest('td').classList.remove('has-rowspan')
                document.querySelector(`#${prevId}`).closest('td').classList.add('has-rowspan')
                // if (rowspan > 2) this.rows[index][this.groupBy] = undefined
                document.querySelector(`#${el.id}`).closest('td').classList.add('hidden', 'special-hidden')
              } else {
                prevValue = el.attributes.currentValue?.nodeValue || ''
                prevGroup = el.attributes.canGroupBy?.nodeValue || ''
                prevId = el.id
                rowspan = 1
              }
            }
          })
        }, 0)
      }
    },
    currentItems () {
      return this.rows.map(row => {
        const myRow = {}
        Object.keys(row).map(key => {
          if (this.mySchema.map(el => el.ownId).includes(key) && key !== 'actions') {
            myRow[key] = row[key]
          }
        })
        return myRow
      })
    },
    selectAll (value, ogn) {
      this.selectedAll = value
      this.mySelectedRows = value === true ? this.rows.filter(el => !el.disabled_checkbox).map(({id}) => id) : []
    },
    callSortByCustom (sortAs, element) {
      if (!this.sortCustomEle.active || this.sortCustomEle.active !== element) { // Activar el sort "ASC" por primera vez o click en otro elemento
        this.sortCustomEle = {
          icon: 'up',
          active: element,
          type: 'asc'
        }
      }
      else if (this.sortCustomEle.active === element) { // Cambiar el orden
        if (this.sortCustomEle.icon === 'down') { // Si esta "DESC" se elimina el orden
          this.sortCustomEle = {
            icon: 'up',
            active: null,
            type: null
          }
        } else { // Si esta "ASC" cambiar a "DESC"
          this.sortCustomEle = {
            ...this.sortCustomEle,
            icon: 'down',
            type: 'desc'
          }
        }
      }
      this.sortByCustom(sortAs, this.sortCustomEle.type)
    },
    onRowSelected (items) {
      this.innerSelectedRows = items.map(el => el.id)
    },
    setColMarked (key) {
      const index = this.mySchema.findIndex(el => el.key === key)
      if (Array.isArray(this.mySchema[index].class) && this.mySchema[index].class.includes('cell-marked')) {
        this.mySchema[index].class = this.mySchema[index].class.filter(el => el !== 'cell-marked')
      } else {
        this.mySchema[index].class = ['cell-marked', 'cursor-pointer'].concat(this.mySchema[index].class)
      }
      // this.innerKey++
      this.mySchema = [...this.mySchema]
      // if (this.innerSelectedRows.length > 0) {
      //   this.innerSelectedRows.map(id => {
      //     this.$refs[`ref${this.id}`].selectRow(id)
      //   })
      // }
    },
    initializeColumns() {
      // Primero aplicamos el filtro de permisos
      this.columnOptions = this.schema.map(col => ({
        key: col.key,
        label: col.label
      }))
      if (this.defaultColumns?.length > 0) {
        this.selectedColumns = this.defaultColumns
      } else {
        this.selectedColumns = this.columnOptions.map(col => col.key)
      }

      // Asegurarse de que al menos una columna esté seleccionada
      if (this.selectedColumns?.length === 0 && this.columnOptions?.length > 0) {
        this.selectedColumns = [this.columnOptions[0].key]
      }
      this.checkSelectAll()
    },
    updateVisibleColumns() {
      if (this.columnFilter) {
        if (this.selectedColumns?.length === 0) {
          this.restoreDefaultColumns()
        }
        this.checkSelectAll()
        this.innerKey++ // Forzar la actualización de la tabla
      }
    },
    restoreDefaultColumns() {
      if (this.defaultColumns && this.defaultColumns?.length > 0) {
        // Usamos las columnas por defecto, asegurándonos de que existan en las opciones
        this.selectedColumns = this.defaultColumns.filter(col =>
          this.columnOptions.some(option => option.key === col)
        )
      } else {
        // Si no hay columnas por defecto o no son válidas, seleccionamos la primera
        this.selectedColumns = this.columnOptions?.length > 0 ? [this.columnOptions[0].key] : []
      }
    },
    toggleAllColumns(checked) {
      if (this.columnFilter) {
        if (checked) {
          this.selectedColumns = this.columnOptions.map(col => col.key)
        } else {
          this.restoreDefaultColumns()
        }
        this.updateVisibleColumns()
      }
    },
    checkSelectAll() {
      this.selectAllColumns = this.selectedColumns?.length === this.columnOptions?.length
    }
  }
}
</script>

<style lang="scss">
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css);
[dir] .table th, [dir] .table td {
    padding: 0.72rem 1.3rem 0.72rem 0.8rem !important;
}
.table.b-table {
  color: #6e6b7b !important;
  > thead > tr {
    background-color: #f3f2f7;
    > * {

      vertical-align: middle;
      > * {
          text-transform: capitalize !important;
          font-size: 1rem;
      }
    }
  }
  > * > tr {
    > * {
      vertical-align: middle;
    }
    > *:last-child {
      padding-right: 1.6rem !important;
    }
    > *:first-child {
      padding-left: 1.6rem !important;
    }
  }
}
td.wrappable-text > span {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
  //  padding: 0.75rem 0.75rem 0 0.75rem !important;
}
// table.b-table[aria-busy='true']{
//   height: 50vh;
// }
// .b-table-busy-slot > td{
//   height: 50vh;
// }
.is-switch {
  color:gray;
}
.is-switch-true:hover,
.is-switch-true {
  color: #11c611 !important;
}
.btn:focus.is-switch, .btn.focus.is-switch,
.btn:focus.is-switch-true, .btn:focus.is-switch-true{
  box-shadow: none;
}
.w-checkbox-table{
  width: 68px;
}
.no-info, .no-info-center{
  vertical-align: middle;
  color: #c2c2c2 !important;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.no-info{
  padding-left: 50%;
}
.cell-marked, .b-table-row-selected > td{
  background: #4A7BBE30 !important
}
.cell-marked-2{
  background: #4A7BBE30 !important
}
.table-markCol > table th {
  cursor: pointer;
  > span {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.header-icon-sort{
  display: flex;
  justify-content: space-between;
}
.header-icon-sort > img{
  width: 1rem;
  margin-left: 5px;
}
.header-icon-sort--disabled > img {
  opacity: 0.3;
}

.customize-tooltip {
  background: #E6EFFA !important;
  width: 60em !important;
  max-width: 60em !important;
}
.customize-tooltip .popover-header{
  background: #E6EFFA !important;
  border: none !important;
  color: #0169DE !important;
  font-size: 10px !important;
  font-family: Montserrat !important;
  line-height: 12.19px !important;
  text-decoration: underline !important;
  font-weight: bold;
}
.customize-tooltip .popover-body{
  background: #E6EFFA !important;
  width: 60em !important;
  max-width: 60em !important;
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #E6EFFA !important;
}

.ul-tooltip{
  list-style-type: none;
  padding: 0;
}
.li-tooltip{
  display: flex;
}
.item-tooltip {
    position: relative;
    display: inline-block;
    top: 3px;
    height: 1em;
    width: 1em;
    border-radius: 1.5em;
    margin-right: 0.3em;
    padding: 0.5em;
}
.tooltip-text {
  font-size: 10px;
  font-family: Montserrat;
  line-height: 12.19px;
  margin-top: 0.3em;
}
// Filtro de columnas estilos

.btn-outline-gray,
.btn-outline-secondary {
  border: 1px solid #D8D6DE !important;
  color: #6E6B7B !important;
  background-color: transparent !important;
}

#dropdown-filter-input {
  position: relative;
  padding: 0.5rem 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

#dropdown-filter-input::after {
  display: none;
}

.filterInput-menu-class {
  max-height: 300px; /* Ajuste este valor según sus necesidades */
  overflow-y: auto;
  border: 1px solid #E0E0E0 !important;
  border-radius: 0.375rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 0.5rem 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.filterInput-menu-class .filterInput-overlay {
  padding: 0.5rem 1rem;
}

.filterInput-menu-class .filterInput-overlay .b-form-checkbox {
  margin-bottom: 0.5rem;
}

.filterInput-menu-class .filterInput-overlay .b-form-checkbox label {
  font-size: 0.9rem;
  color: #6E6B7B;
}

.filterInput-dropdown-divider {
  margin: 0.5rem 0;
  opacity: 0.2;
}

.filterInput-menu-class .b-form-checkbox:last-child {
  margin-top: 0.5rem;
  border-top: 1px solid #E0E0E0;
  padding-top: 0.5rem;
}

.filterInput-menu-class .b-form-checkbox:last-child label {
  font-weight: 500;
  color: #5E5873;
}

ul.dropdown-menu.filterInput-menu-class {
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
}

.badge-cnt-filter {
  padding: 0.25rem 0.5rem !important;
  border-radius: 0.25rem !important;
  background-color: #E0E0E0;
  color: #5E5873;
}

.filterInput-XIcon {
  cursor: pointer;
  color: #6E6B7B;
}

.btn-cnt-filter--icon > button {
  padding: 0.5rem;
}

.btn-cnt-filter--icon > button > span.spinner-border {
  width: 1.25rem;
  height: 1.25rem;
}

.column-checkbox-group .custom-checkbox {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.column-checkbox-group .custom-checkbox:last-child {
  border-bottom: none;
}

.select-all-checkbox {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 2px solid #d0d0d0;
}

.customize-tooltip {
  background: #E6EFFA !important;
  width: 60em !important;
  max-width: 60em !important;
}
.customize-tooltip .popover-header{
  background: #E6EFFA !important;
  border: none !important;
  color: #0169DE !important;
  font-size: 10px !important;
  font-family: Montserrat !important;
  line-height: 12.19px !important;
  text-decoration: underline !important;
  font-weight: bold;
}
.customize-tooltip .popover-body{
  background: #E6EFFA !important;
  width: 60em !important;
  max-width: 60em !important;
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #E6EFFA !important;
}

.ul-tooltip{
  list-style-type: none;
  padding: 0;
}
.li-tooltip{
  display: flex;
}
.item-tooltip {
    position: relative;
    display: inline-block;
    top: 3px;
    height: 1em;
    width: 1em;
    border-radius: 1.5em;
    margin-right: 0.3em;
    padding: 0.5em;
}
.tooltip-text {
  font-size: 10px;
  font-family: Montserrat;
  line-height: 12.19px;
  margin-top: 0.3em;
}

.filter-columns-dropdown {
  margin-right: 6rem;
  .dropdown-menu {
    min-height: 36rem;
    height: 100%;
  }
}
</style>
