import Vue from 'vue'
import CustomAlert from './CustomAlert.vue'

const CustomAlertConstructor = Vue.extend(CustomAlert)

const customAlerts = {
  install(Vue) {
    Vue.prototype.$customSuccess = function(options) {
      const instance = new CustomAlertConstructor({
        propsData: {
          ...options,
          title: options.title ?? 'Éxito'
        }
      })

      instance.$mount()
      document.body.appendChild(instance.$el)

      instance.$on('close', () => {
        document.body.removeChild(instance.$el)
        instance.$destroy()
      })
    }

    Vue.prototype.$customError = function(options) {
      const instance = new CustomAlertConstructor({
        propsData: {
          ...options,
          title: options.title ?? 'Error',
          type: 'danger',
          icon: 'XCircleIcon',
          info: (!options.info && !options.multipleOption) ? 'Inténtelo de nuevo más tarde.<br>Si el inconveniente persiste, ponte en contacto con Support para recibir asistencia.' : options.info,
          recallAction: options.recallAction ? () => {
            return new Promise((resolve, reject) => {
              try {
                const result = options.recallAction()
                if (result && typeof result.then === 'function') {
                  result.then(resolve).catch(reject)
                } else {
                  resolve(result)
                }
              } catch (error) {
                reject(error)
              }
            })
          } : null
        }
      })

      instance.$mount()
      document.body.appendChild(instance.$el)

      instance.$on('close', () => {
        document.body.removeChild(instance.$el)
        instance.$destroy()
      })
    }
  }
}

export default customAlerts
