export default {
  state: {
    marketcenters: [],
    paramsMkc: {},
    warehouses: [],
    fieldsMarketcenter: [],
    deliveriesSuccess: {},
    deliveriesFailed: [],
    marketcentersByShipperId:[],
    products: {},
    summaries:[],
    document: [],
    printerDeliveries: []
  },
  getters: {
    getMarketcenters: state => state.marketcenters,
    getWarehouses: state => state.warehouses,
    getFieldsMarketcenter: state => state.fieldsMarketcenter,
    getDeliveriesSuccess: state => state.deliveriesSuccess,
    getDeliveriesFailed: state => state.deliveriesFailed,
    getMarketcenterByShipperId: state => state.marketcentersByShipperId,
    getProductsMarketcenter: state => state.products,
    getSummaries: state => state.summaries,
    getPrintSummaries: state => state.document,
    getPrinterDeliveries: state => state.printerDeliveries,
    getParamsMkc: state => state.paramsMkc
  },
  mutations: {
    setMarketcenters (state, val) {
      state.marketcenters = val.rows
    },
    setWarehouses (state, val) {
      state.warehouses = val.rows
    },
    setFieldsMarketcenter (state, val) {
      state.fieldsMarketcenter = val.rows
    },
    setDeliveriesSuccess (state, val) {
      state.deliveriesSuccess = val
    },
    setDeliveriesFailed (state, val) {
      state.deliveriesFailed = val
    },
    setMarketcenterByShipperId (state, val) {
      state.marketcentersByShipperId = val.rows
    },
    setProductsMarketcenter (state, val) {
      state.products = val
    },
    setSummaries (state, val) {
      state.summaries = val
    },
    setPrintSummaries (state, val) {
      state.document = val.data
    },
    setPrinterDeliveries (state, val) {
      state.printerDeliveries = val
    },
    setParamsMkc(state, val) {
      state.paramsMkc = {...val}
    }
  },
  actions: {
    dispatchSetParamsMkc({ commit }, val) {
      commit('setParamsMkc', val)
    }
  }
}